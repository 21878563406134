import {errorManagementHandler, setLoading, setMessage} from "./theme";
import axios from "axios";
import {API_BASE_URL, LMS_URL} from "../../../util/constant";
import * as actionTypes from '../actionTypes';
import {checkRouteRole} from "../../../util/securityUtil";
import i18n from "i18next";

export const updateUserData = userData => {
    return {
        type: actionTypes.UPDATE_USER_DATA,
        userData
    }
};

export const updateUserInstitutionData = userInstitutionData => {
    return {
        type: actionTypes.UPDATE_USER_INSTITUTION_DATA,
        userInstitutionData
    }
};

export const updateCurrentUserInstitution = currentUserInstitution => {
    localStorage.setItem('institutionId', currentUserInstitution.institution);
    localStorage.setItem('role', currentUserInstitution.role);
    return {
        type: actionTypes.UPDATE_CURRENT_USER_INSTITUTION,
        currentUserInstitution: currentUserInstitution
    }
};

export const updateInstitution = institutionData => {
    return {
        type: actionTypes.UPDATE_INSTITUTION,
        institutionData: institutionData
    }
};

export const getUserData = (history, token, institutionId, role, pathname) => {
    return dispatch => {
        dispatch(setLoading(true));
        if (!!token) {
            localStorage.setItem('token', token);
            axios
                .get(`${API_BASE_URL}/auth/userData/getInstitutionUserData/${institutionId}`, {headers: {Authorization: `Bearer ${token}`}})
                .then(res => {
                    const userData = res.data.user_data;
                    const institutionData = res.data.institution_data;
                    let userInstitutionData = res.data.user_institution_data;
                    let userInstitution = userInstitutionData.find(item => String(item.role) === String(role));
                    if (!userInstitution) window.open(`${LMS_URL}/lms`, '_parent');
                    if (!checkRouteRole(pathname, role)) history.replace('/home');
                    dispatch(updateUserInstitutionData(userInstitutionData))
                    dispatch(updateCurrentUserInstitution(userInstitution));
                    dispatch(updateInstitution(institutionData));
                    dispatch(updateUserData(userData));
                    dispatch(setLoading(false));
                })
                .catch(e => dispatch(errorManagementHandler(e)));
        } else {
            dispatch(logout());
        }
    };
};

export const massiveUploadInstitutionUser = (userInstitutionId, data, callback) => {
    return dispatch => {
        dispatch(setLoading(true));
        const token = localStorage.getItem('token');
        axios
            .post(`${API_BASE_URL}/institution/user/createMassive/${userInstitutionId}`, data, {headers: {Authorization: `Bearer ${token}`}})
            .then(() => {
                dispatch(setMessage('success', i18n.t('UPLOAD_TEACHER_MASSIVE_SUCCESS')))
                dispatch(setLoading(false));
                callback();
            })
            .catch(e => dispatch(errorManagementHandler(e)));
    };
};

export const editUserGeneralData = (userInstitutionId, data, showLoading=true) => {
    return (dispatch, getState) => {
        const token = localStorage.getItem('token');
        if (showLoading) dispatch(setLoading(true));
        axios
            .put(`${API_BASE_URL}/institution/user/updateProfile/${userInstitutionId}`, data, {headers: {Authorization: `Bearer ${token}`}})
            .then(() => {
                const currentUserInstitution = {
                    ...getState().auth.currentUserInstitution,
                    ...data,
                };
                const oldData = getState().auth.userInstitutionData;
                let newData = [];
                oldData.forEach(item => {
                    if (item.id === userInstitutionId) newData.push({...item, ...data});
                    else newData.push(item);
                });
                dispatch(updateCurrentUserInstitution(currentUserInstitution));
                dispatch(updateUserInstitutionData(newData));
                if (showLoading) dispatch(setLoading(false));
            })
            .catch(e => dispatch(errorManagementHandler(e)));
    };
};

export const logout = () => {
    return dispatch => {
        dispatch(updateUserInstitutionData(null));
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.open(`${LMS_URL}/auth/login`, '_parent');
    }
};