import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    courseData: null,
    currentCourse: null,
};

const updateCourseData = (state, action) => {
    return updateState(
        state,
        {
            courseData: action.courseData
        }
    )
};

const updateCurrentCourse = (state, action) => {
    return updateState(
        state,
        {
            currentCourse: action.currentCourse
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_COURSE_DATA: return updateCourseData(state, action);
        case actionTypes.UPDATE_CURRENT_COURSE: return updateCurrentCourse(state, action);
        default: return state;
    }
};

export default reducer;