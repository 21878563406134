import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    teacherData: null,
    filterObj: {
        text: null,
        course: null,
        status: null,
    },
};

const updateTeacherData = (state, action) => {
    return updateState(
        state,
        {
            teacherData: action.teacherData.data,
            total: action.teacherData.total,
            page: action.teacherData.page,
        }
    )
};

const updateFilterObj = (state, action) => {
    return updateState(
        state,
        {
            filterObj: {
                ...state.filterObj,
                ...action.filterObj
            }
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TEACHER_DATA: return updateTeacherData(state, action);
        case actionTypes.UPDATE_TEACHER_FILTER: return updateFilterObj(state, action);
        default: return state;
    }
};

export default reducer;