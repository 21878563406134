const availableRouteObj = {
    '1': [
        '/home',
        '/dashboard',
        '/content',
        '/setting',
        '/content/course/create',
        '/content/course/list',
        '/content/course/detail',
        '/content/course/edit',
        '/content/course/teacher',
        '/content/course/student',
        '/teacher',
        '/teacher/create',
        '/teacher/createMassive',
        '/teacher/detail',
        '/student',
        '/student/create',
        '/student/createMassive',
        '/student/detail',
        '/alert',
    ],
    '2': [
        '/home',
        '/dashboard',
        '/content',
        '/setting',
        '/content/course/create',
        '/teacher/createMassive',
        '/content/course/list',
        '/content/course/detail',
        '/content/course/edit',
        '/content/course/teacher',
        '/content/course/student',
        '/teacher',
        '/teacher/create',
        '/teacher/detail',
        '/student',
        '/student/create',
        '/student/createMassive',
        '/student/detail',
        '/alert',
    ],
    '3': [
        '/home',
        '/dashboard',
        '/evaluation',
        '/student',
        '/achievement',
        '/chat',
        '/chat/broadcast/create',
        '/alert',
        '/course',
        '/course/content',
        '/course/info',
        '/course/edit',
        '/course/group',
        '/course/group/create',
        '/course/group/detail',
        '/course/group/edit',
        '/course/evaluation',
        '/course/evaluation/individual',
        '/course/evaluation/group',
        '/course/student',
        '/course/statistic',
        '/course/create',
        '/course/activity/create',
        '/course/activity/edit',
    ],
    '4': [
        '/home',
        '/dashboard',
        '/chat',
        '/course',
        '/course/content',
        '/course/info',
        '/course/progress',
        '/course/group',
        '/course/group/create',
        '/course/group/detail',
        '/course/group/edit',
    ],
};

export const checkRouteRole = (pathname, role) => {
    const pathArray = pathname.replace('/', '').split('/');
    let routePathname = '';
    pathArray.forEach(item => {
        if (isNaN(parseInt(item))) routePathname += `/${item}`;
    });
    return availableRouteObj[role].indexOf(routePathname) !== -1;
};

export const checkTeacherPermission = (permissionList, requiredPermission) => {
    if (!permissionList) return false;
    if (!requiredPermission) return true;
    return !!permissionList.find(permission => permission.permission === requiredPermission);
}