import * as actionTypes from '../actionTypes';
import i18n from "../../../translations/i18n";
import {logout} from "./auth";

export const setTheme = (theme) => {
	localStorage.setItem('theme', theme);
	return {
		type: actionTypes.SET_THEME,
		theme: theme,
	}
};

export const setMessage = (messageType, message) => {
	return {
		type: actionTypes.SET_MESSAGE,
		messageContent: message,
		messageType: messageType
	}
};

export const setLoading = loading => {
	return {
		type: actionTypes.SET_LOADING,
		loading: loading
	}
};

export const setLanguage = language => {
	return {
		type: actionTypes.SET_LANGUAGE,
		language: language
	}
};

export const setUbotToast = (type, content) => {
	return {
		type: actionTypes.SET_UBOT_TOAST,
		ubotToastType: type,
		ubotToastContent: content,
	}
};

export const setOpenDropdown = (openDropdown) => {
	return {
		type: actionTypes.SET_OPEN_DROPDOWN,
		openDropdown,
	}
};

export const setOpenMobileMenu = (openMobileMenu) => {
	return {
		type: actionTypes.SET_OPEN_MOBILE_MENU,
		openMobileMenu,
	}
};

export const errorManagementHandler = (e, callback) => {
	console.log(e.message);
	return dispatch => {
		if (!!e.response) {
			if (e.response.status === 401) {
				dispatch(logout());
				dispatch(setMessage('error', i18n.t('UNAUTHORIZED')));
			} else if (e.response.status === 403) {
				dispatch(setMessage('error', i18n.t('NO_PERMISSION')));
			} else if (e.response.status === 404) {
				dispatch(setMessage('error', i18n.t('NOT_FOUND')));
			} else if (e.response.status === 405) {
				dispatch(setMessage('error', i18n.t('METHOD_NOT_ALLOWED')));
			} else {
				if (!!e.response.data.code) {
					dispatch(setMessage('error', i18n.t(e.response.data.code)));
				} else {
					dispatch(setMessage('error', i18n.t('INTERNAL_SERVER_ERROR')));
				}
			}
		} else {
			dispatch(setMessage('error', i18n.t('INTERNAL_SERVER_ERROR')));
		}
		dispatch(setLoading(false));
		if (!!callback) callback();
	}
};