export const TRANSLATIONS_EN = {
    // ERROR MESSAGE
    INTERNAL_SERVER_ERROR: 'Internal server error',
    UNAUTHORIZED: 'Unauthorized',
    TOKEN_EXPIRED: 'Token expired',
    NOT_FOUND: 'Service not found',
    METHOD_NOT_ALLOWED: 'Request method not allowed',
    USER_DUPLICATED: 'Duplicated user',
    BAD_REQUEST: 'Bad request',
    USER_NOT_FOUND: 'User not found',
    PHONE_REQUIRED: 'Phone required',
    PHONE_DUPLICATED: 'Duplicated phone number',
    EMAIL_REQUIRED: 'Email required',
    EMAIL_DUPLICATED: 'Duplicated email',
    PASSWORD_REQUIRED: 'Password required',
    WRONG_PASSWORD: 'Wrong password',
    INVALID_USER: 'Invalid user',
    NOT_EMAIL_OWNER: 'You are not owner of this email',
    NOT_PHONE_OWNER: 'You are not owner of this phone',
    EMAIL_NOT_FOUND: 'Email not found',
    PHONE_NOT_FOUND: 'Phone not found',
    SENDING_EMAIL_ERROR: 'Sending email failed',
    SENDING_MESSAGE_ERROR: 'Sending message failed',
    NO_PERMISSION: 'You have no permission to do this action',
    INSTITUTION_NOT_FOUND: 'Institution not found',
    ACCOUNT_NOT_VERIFIED: 'Account not verified',
    INVALID_TOKEN: 'Invalid token',
    SOMETHING_WRONG: 'Something went wrong, please contact with support',
    INVALID_TEACHER: 'Invalid teacher',
    INVALID_STUDENT: 'Invalid student',
    DELETE_NOT_ALLOWED: 'Delete not allowed',
    JOIN_NOT_ALLOWED: 'Join not allowed',
    ACTIVITY_NOT_FOUND: 'Activity not found',
    INSTITUTION_NAME_DUPLICATED: 'Institution with same name already exists',
    NO_MESSAGE_TEMPLATE: 'Message template does not exist',
    NOT_ALLOWED_TO_DELETE: 'Not allowed to delete',

    ERROR_AVATAR_FILE_SIZE: 'File size is too large',
    ERROR_PONDERATION_VALUE: 'Sum of the assessment values should be always 100',

    ACCOUNT_UPDATED_SUCCESS: 'Account updated successfully',
    CONTACT_UPDATED_SUCCESS: 'Contact updated successfully',
    UPDATE_PASSWORD_SUCCESS: 'Password updated successfully',
    SEND_VERIFY_EMAIL_SUCCESS: 'Verify email sent successfully',
    SEND_VERIFY_CODE_SUCCESS: 'Verify code sent successfully',
    CREATE_OWNER_SUCCESS: 'You have added a new owner. The new owner has to verify the account',
    CREATE_ADMIN_SUCCESS: 'You have added a new administrator',
    DELETE_ADMIN_SUCCESS: 'You have deleted an administrator',
    DELETE_TEACHER_SUCCESS: 'You have deleted a teacher / trainer',
    DELETE_TEACHER_MULTIPLE_SUCCESS: 'You have deleted {{number}} teachers / trainers',
    CREATE_COURSE_SUCCESS: 'You have created a course, you must wait for the administrator approval',
    DELETE_STUDENT_MULTIPLE_SUCCESS: 'You have deleted {{number}} students',
    DELETE_STUDENT_SUCCESS: 'You have deleted a student',
    UPLOAD_TEACHER_MASSIVE_SUCCESS: 'Upload is running in background, you will receive a notification after completed',
    CREATE_SCALE_SUCCESS: 'You have created a new scale successfully!',
    CREATE_TEACHER_SUCCESS: 'You have created a teacher successfully!',
    CREATE_STUDENT_SUCCESS: 'You have created a student successfully!',
    CREATE_ACTIVITY_SUCCESS: 'You have successfully loaded {{activityType}} {{activityName}}!',
    CREATE_BROADCAST_SUCCESS: 'You have created a broadcast list successfully',
    DUPLICATE_COURSE_SUCCESS_ADMIN: 'You have duplicated a course, now you should approve it',
    DUPLICATE_COURSE_SUCCESS_TEACHER: 'You have duplicated a course, you should wait for the admin approval',
    ARCHIVE_COURSE_SUCCESS: 'You have archived {{courseName}}',
    DELETE_COURSE_SUCCESS: 'Your course will be deleted in 24 hours',
    SYNC_FUNCTION_IS_IN_BACKGROUND: 'Sync function is running in background, you will be notified after finished',
    SYNC_BACKGROUND_FUNCTION_SUCCEEDED: 'Sync function succeeded in background, please reload page to see results',

    // FORM VALIDATION
    fieldRequired: 'This field is required',
    fieldInvalid: 'Invalid value',
    weakPassword: 'Weak password',
    fieldUnconfirmed: 'Confirm field correctly',
    fieldMaxLength: 'Maximum {{length}} characters',

    // FORM PLACEHOLDER
    user: 'User',
    password: 'Password',
    emailOrPhone: 'E-mail address or Cellphone number',
    enterCode: 'Enter your code',
    selectArea: 'Select areas',
    selectNativeLanguage: 'Select native language',
    selectOtherLanguage: 'Select other language',
    select: 'Select',
    placeholderExample: 'Example: {{text}}',
    filterStudyArea: 'Filter study area',
    filterCareerIndependentCourse: 'Filter career or independent course',
    activityDescriptionPlaceholder: 'In a few sentences describe what the student must do',
    noDate: 'No date',
    noTime: 'No time',
    liveVideoUrl: 'Live video url',
    fileUrl: 'File url',

    // FORM LABEL
    name: 'Name',
    lastname: "Last name",
    secondName: "Second name",
    surname: "Surname",
    secondLastname: "Second last name",
    secondSurname: "Second surname",
    birthday: "Birth date",
    nationality: 'Nationality',
    idNumber: 'ID number',
    email: 'Email',
    phone: 'Mobile',
    repeatPassword: 'Repeat password',
    enterEmailPhone: 'Enter your email, phone',
    verificationCodeToEmail: 'A verification code to retrieve the password was sent to your email',
    enterNewPassword: 'Enter your new password',
    repeatNewPassword: 'Repeat your new password',
    country: 'Country',
    city: 'City',
    address: 'Address',
    state: 'State',
    secondNationality: 'Second nationality',
    secondIdentification: 'Second identification',
    secondPassport: 'Second passport',
    avatarOption1: "Select image from computer (maximum 1MB)",
    avatarOption2: "Load image from link",
    avatarOption3: "Use default image",
    editImage: "Edit image",
    ok: "OK",
    actual: "Actual",
    newPassword: "New password",
    confirmPassword: "Confirm new password",
    interestArea: "Interest areas",
    nativeLanguage: "Native language",
    otherLanguage: "Other language",
    sector: "Sector",
    subSector: "Sub - Sector",
    numberOfStudent: "Number of students",
    noInfo: "No info",
    numberOfCourse: "Number of courses",
    institutionName: 'Institution name',
    institutionEmail: 'Institution email',
    institutionPhone: 'Contact phone number',
    dateOfOrigin: 'Date of origin',
    description: 'Description',
    googleMapLink: 'Link to google map',
    website: 'Website',
    title: 'Title',
    buttonText: 'Button text',
    buttonLink: 'Button link',
    studyArea: 'Study area',
    independenceCourseCareer: 'Independence course / career',
    filteredCourse: 'Filtered course',
    courseSet: 'Course set',
    courseImageOption1: 'Upload file from your computer ({{size}}MB max)',
    courseImageDescription1: 'The image format must be JPG or PNG and be larger than 300px x 150px.',
    courseImageOption2: 'Upload file from link',
    courseImageOption3: 'Use default image',
    courseCoverLetterFile: 'Upload file from your computer (3MB max)',
    courseCoverLetterLink: 'Upload file from link',
    courseCoverLetterFileDescription: 'The file format must be docx or pdf',
    selectIfOpenCourse: 'Select if it is a open course',
    yes: 'Yes',
    no: 'No',
    totalAcademicHour: 'Total academic hours',
    equivalentCredit: 'Equivalent credits / ECTS',
    synchronous: 'Synchronous',
    asynchronous: 'Asynchronous',
    classDay: 'Class day{{index}}',
    time: 'Time',
    startTime: 'Start time',
    finishTime: 'Finish time',
    fileFormat: 'File format',
    selectLevel: 'Select a level',
    selectOrder: 'Select order',
    selectLevelOrder: 'Select {{text}} order',
    estimatedTime: 'Estimated time (hrs)',
    indicateLocation: 'Indicate the location',
    indicateGoogleMap: 'Indicate the link to Google maps',
    indicatePlatform: 'Indicate the link to the platform',
    indicateLinkTitle: 'Indicate the link title',
    indicateLinkOfFile: 'Indicate the link to the file',
    comment: 'Comment',
    expireDate: 'Expire date',
    expireTime: 'Expire time',
    notificationDate: 'Notification date',
    notificationTime: 'Notification time',
    file: 'File',
    easyLMSExamId: 'Easy LMS exam ID',
    activeFrom: 'Active from',
    activeTo: 'Active to',
    sendNotificationWhenMaterialBecomesAvailable: 'Send notification when material becomes available',

    // WEEKDAY
    weekday0: 'Sunday',
    weekday1: 'Monday',
    weekday2: 'Tuesday',
    weekday3: 'Wednesday',
    weekday4: 'Thursday',
    weekday5: 'Friday',
    weekday6: 'Saturday',

    // SIDE MENU
    home: 'Home',
    statistic: 'History and statistic',
    dashboard: 'My dashboard',
    setting: 'Setting',
    content: 'Content',
    teacher: 'Teacher',
    student: 'Student',
    alert: 'Alert',
    myEvaluation: 'My evaluation',
    achievement: 'Achievement',
    myAchievement: 'My achievement',
    myGroup: 'My group',
    myChat: 'My chat',
    myProgress: 'My progress',
    myCourses: 'My courses',
    myContents: 'My contents',
    courseInfo: 'Course info',
    myEvaluations: 'My evaluations',
    historyAndStatistics: 'History & statistics',
    myGroups: 'My groups',
    sidebarFooter: '© All rights reserved',
    menuDescription_dashboard: 'Keep track of your To Do, Ongoing and Completed activities up to date.',
    menuDescription_setting: 'Customise the look and feel of your platform',
    menuDescription_courses: 'Consult and keep up to date with the content of your training programme',
    menuDescription_teacher: 'Consult, add or edit your teachers\' information',
    menuDescription_student: 'Consult, add or edit student user information',
    menuDescription_alert: 'Check the educational behaviour and performance of your users',
    menuDescription_myEvaluation: 'Visualize the performance, evaluate the competencies and objectives of the activities of the users of your course in a group and individual way.',
    menuDescription_achievement: 'Congratulations! In this space you will have the opportunity to view the rewards for outstanding or outstanding participation.',
    menuDescription_myAchievement: 'Congratulations! In this space you will have the opportunity to view the rewards for outstanding or outstanding participation.',
    menuDescription_myGroup: 'View, add or edit information about your study groups.',
    menuDescription_myChat: 'Active communications with smarty, teachers and students. Creation and sending of Diffusion Lists',
    menuDescription_myChat_student: 'Active communications with smarty, teachers and students',
    menuDescription_myProgress: 'View your course progress',
    menuDescription_myCourses: 'Consult, edit or create content for your courses',

    // STUDY AREA NAME
    studyAreaName_art_architecture_design: 'Art, Architecture and Design',
    studyAreaName_biological_agriculture: 'Biological and Agricultural Sciences',
    studyAreaName_economic_business: 'Economic Sciences and Business Management',
    studyAreaName_educational: 'Educational Sciences',
    studyAreaName_engineering: 'Engineering',
    studyAreaName_exact: 'Exact Sciences',
    studyAreaName_health: 'Health Sciences',
    studyAreaName_language: 'Languages',
    studyAreaName_social_humanity: 'Social Sciences and Humanities',
    studyAreaName_technology: 'Technology',
    studyAreaName_null: 'Other',

    // COURSE USER ROLE
    courseUserRole_teacher_instructor: 'Instructor',
    courseUserRole_teacher_assistance: 'Assistant Professor/Teacher',
    courseUserRole_teacher_associate: 'Associate Professor/Teacher',
    courseUserRole_teacher_professor: 'Professor/Teacher',

    // COURSE USER PERMISSION
    courseUserPermission_manage_content: 'Create / Upload contents of course',
    courseUserPermission_manage_activity: 'Qualify / Take attendance / Comment on activity',
    courseUserPermission_chat: 'Chat with student',
    courseUserPermission_manage_course: 'Edit course info',

    // HEADER
    notification: 'Notification',
    notifications: 'Notifications',
    makeAsReadAll: 'Make as read all',
    makeAsRead: 'Make as read',
    makeAsUnread: 'Make as unread',
    deleteAll: 'Delete all',
    unread: 'Unread',
    read: 'Read',
    goBackToUbotPlatform: 'Go back to Ubot platform',
    changeRole: 'Change role',
    academic: 'Academic',
    analytics: 'Analytics',
    communication: 'Communication',

    // NOTIFICATION
    notificationDescription_teacher_create_course_admin: '<b>{{creatorName}}</b> has created a new course and wait for your approval',
    notificationAction_teacher_create_course_admin: 'GO TO THE COURSE',
    notificationDescription_admin_approve_course_teacher: '<b>{{creatorName}}</b> has approved the course <b>{{courseName}}</b>',
    notificationAction_admin_approve_course_teacher: 'GO TO THE COURSE',
    notificationDescription_admin_enroll_course_user: 'You were enrolled as <b>{{ownerRoleName}}</b> on the course <b>{{courseName}}</b>',
    notificationAction_admin_enroll_course_user: 'GO TO THE COURSE',
    notificationDescription_system_massive_teacher_admin: 'The massive creation of  <b>TEACHER</b> was finished successfully',
    notificationAction_system_massive_teacher_admin: 'TEACHER LIST',
    notificationDescription_system_massive_student_admin: 'The massive creation of  <b>STUDENT</b> was finished successfully',
    notificationAction_system_massive_student_admin: 'STUDENT LIST',

    notificationDescription_message_from_teacher: '<b>{{creatorName}}</b> has sent you a message',
    notificationAction_message_from_teacher: 'TO THE MESSAGE',
    notificationDescription_broadcast_from_teacher: '<b>{{creatorName}}</b> has sent you a notification',
    notificationAction_broadcast_from_teacher: 'TO THE MESSAGE',
    notificationDescription_course_message_from_teacher: 'You have a new message in <b>{{courseName}}</b> chat',
    notificationAction_course_message_from_teacher: 'TO THE MESSAGE',
    notificationDescription_message_from_admin: '<b>{{creatorName}}</b> has sent you a message',
    notificationAction_message_from_admin: 'TO THE MESSAGE',
    notificationDescription_broadcast_from_admin: '<b>{{creatorName}}</b> has sent you a notification',
    notificationAction_broadcast_from_admin: 'TO THE MESSAGE',
    notificationDescription_course_message_from_student: 'You have a new message in <b>{{courseName}}</b> chat',
    notificationAction_course_message_from_student: 'TO THE MESSAGE',
    notificationDescription_group_message_from_student: 'You have a new message in <b>{{groupName}}</b> chat',
    notificationAction_group_message_from_student: 'TO THE MESSAGE',

    notificationDescription_student_request_join_group: '<b>{{creatorName}}</b> want to join to the group <b>{{groupName}}</b>',
    notificationAction_student_request_join_group: 'TO THE GROUP VIEW',
    notificationDescription_student_deliver_individual_activity: '<b>{{creatorName}}</b> has deliver an activity for qualifying',
    notificationAction_student_deliver_individual_activity: 'TO THE ACTIVITY',
    notificationDescription_student_deliver_group_activity_to_teacher: 'The group <b>{{groupName}}</b> has deliver an activity for qualifying',
    notificationAction_student_deliver_group_activity_to_teacher: 'TO THE ACTIVITY',
    notificationDescription_student_deliver_group_activity_to_student: '<b>{{creatorName}}</b> has deliver an activity for qualifying on the group <b>{{groupName}}</b>',
    notificationAction_student_deliver_group_activity_to_student: 'TO THE ACTIVITY',
    notificationDescription_student_write_activity_comment: '<b>{{creatorName}}</b> has write a new comment on the activity <b>{{activityName}}</b>',
    notificationAction_student_write_activity_comment: 'TO THE COMMENT ACTIVITY',
    notificationDescription_teacher_write_activity_comment: '<b>{{creatorName}}</b> has write a new comment on the activity <b>{{activityName}}</b>',
    notificationAction_teacher_write_activity_comment: 'TO THE COMMENT ACTIVITY',
    notificationDescription_teacher_qualify_activity: '<b>{{creatorName}}</b> has qualified the activity <b>{{activityName}}</b>',
    notificationAction_teacher_qualify_activity: 'TO THE ACTIVITY',
    notificationDescription_teacher_create_activity: '<b>{{creatorName}}</b> has created a new activity <b>{{activityName}}</b>',
    notificationAction_teacher_create_activity: 'TO THE ACTIVITY',

    notificationDescription_teacher_update_activity_content: '<b>{{creatorName}}</b> has added new content to the activity <b>{{activityName}}</b>',
    notificationAction_teacher_update_activity_content: 'TO THE ACTIVITY',

    notificationDescription_activity_expire_in_one_day: 'Remember that the activity <b>{{activityName}}</b> will be expired on <b>{{datetime}}</b>',
    notificationAction_activity_expire_in_one_day: 'TO THE ACTIVITY',
    notificationDescription_activity_notification_instantly: 'Remember that on <b>{{datetime}}</b> you have the evaluation <b>{{activityName}}</b>',
    notificationAction_activity_notification_instantly: 'TO THE ACTIVITY',
    notificationDescription_activity_start_in_one_hour: 'Remember that the activity <b>{{activityName}}</b> start in one hour',
    notificationAction_activity_start_in_one_hour: 'TO THE ACTIVITY',
    notificationDescription_activity_start_in_one_day: 'Remember that the activity <b>{{activityName}}</b> start tomorrow',
    notificationAction_activity_start_in_one_day: 'TO THE ACTIVITY',
    
    notificationDescription_ponderation_error: 'The total ponderation on the course <b>{{courseName}}</b> is not 100%',
    notificationAction_ponderation_error: 'TO THE PONDERATION TABLE',

    notificationDescription_early_warning_performance: 'Your performance in the course <b>{{courseName}}</b> is lower than the stipulated.',
    notificationAction_early_warning_performance: 'TO MY PROGRESS',
    notificationDescription_final_warning_performance: 'Your performance in the course <b>{{courseName}}</b> is lower than the stipulated.',
    notificationAction_final_warning_performance: 'TO MY PROGRESS',
    notificationDescription_early_warning_pass_rate: 'Your pass rate in the course <b>{{courseName}}</b> is lower than the stipulated.',
    notificationAction_early_warning_pass_rate: 'TO MY PROGRESS',
    notificationDescription_final_warning_pass_rate: 'Your pass rate in the course <b>{{courseName}}</b> is lower than the stipulated.',
    notificationAction_final_warning_pass_rate: 'TO MY PROGRESS',
    notificationDescription_early_warning_activity_delivery_status: 'You had delivered many activities out of time in the course <b>{{courseName}}</b>',
    notificationAction_early_warning_activity_delivery_status: 'TO MY PROGRESS',
    notificationDescription_final_warning_activity_delivery_status: 'You had not delivered many activities in the course <b>{{courseName}}</b>',
    notificationAction_final_warning_activity_delivery_status: 'TO MY PROGRESS',
    notificationDescription_early_warning_qualification_time: 'In the course <b>{{courseName}}</b> there is a delay in the qualifying time',
    notificationAction_early_warning_qualification_time: 'TO THE ALERT MENU',
    notificationDescription_final_warning_qualification_time: 'In the course <b>{{courseName}}</b> there is a delay in the qualifying time',
    notificationAction_final_warning_qualification_time: 'TO THE ALERT MENU',
    notificationDescription_alert_reminder_admin: 'Remember to check the educational alerts of your institution',
    notificationAction_alert_reminder_admin: 'TO THE ALERT MENU',
    notificationDescription_alert_reminder_teacher: 'Remember to check the educational alerts of your courses',
    notificationDescription_celery_background_task_finished: 'Background task <b>{{taskName}}</b> finished',

    backgroundTask_: '',
    backgroundTask_sync_blackboard_educational_alert: 'Sync blackboard and educational alert',
    backgroundTask_sync_blackboard_score_educational_alert: 'Sync blackboard score and educational alert',
    backgroundTask_sync_admin_class: 'Sync AdminClass',

    // ROLE NAME
    roleName1: 'Owner',
    roleName2: 'Admin',
    roleName3: 'Teacher',
    roleName4: 'Student',

    // COMMON BUTTON
    delete: 'Delete',
    view: 'View',
    edit: 'Edit',
    search: "Search",
    next: 'Next',
    back: 'Back',
    goBack: 'Go back',
    add: 'Add',
    remove: 'Remove',
    finish: 'Finish',
    closePreview: 'Close preview',
    addEditTeacher: 'Add / Edit teacher',
    addEditStudent: 'Add / Edit student',
    download: 'Download',

    // HEADER
    courses: 'Courses',
    tutorials: 'Tutorials',
    createYourSite: 'Create your site',
    profile: 'Profile',
    configuration: 'Configuration',
    messageAndNotification: 'Messages and notifications',
    darkMode: 'Dark mode',
    signOut: 'Logout',

    // HOME PAGE
    welcomeUser: 'Welcome {{username}}!',
    platformNews: 'Platform news',
    showBy: 'Show by',
    date: 'Date',
    youAreAlreadyPartOfInstitution: 'You are already part of {{institution}}',
    createYourFirstSlide: 'Create your first slide',
    noNewsFromInstitution: 'There is no news from your institution',
    addNews: 'Add news',
    weeks: 'weeks',

    // INSTITUTION SETTING PAGE
    visualSetting: 'Visual setting',
    homeSetting: 'Home setting',
    scaleSetting: 'Note scale',

    disable: 'Disable',
    enable: 'Enable',
    header: 'Header',
    headerOption_default: 'Default UBOT',
    headerOption_light: 'Light',
    headerOption_neutral: 'Neutral',
    headerOption_dark: 'Dark',
    active: 'Active',
    preview: 'Preview',
    choose: 'Choose',
    generalColor: 'General color',
    colorOption_default: 'Default UBOT',
    colorOption_happy: 'Happy',
    colorOption_warm: 'Warm',
    colorOption_ocean: 'Ocean',
    colorOption_forest: 'Forest',
    colorOption_neutral: 'Neutral',
    main: 'Main',
    complementary: 'Complementary',

    imageCarousel: 'Image carousel',
    addNewSlide: 'Add new slide',
    slide: 'Slide',
    button: 'Button',
    url: 'URL',
    confirmDeleteCarouselTitle: 'Do you want to delete Slider {{slideNumber}}?',
    confirmDeleteCarouselDescription: 'This action is final, if you delete this slider you will not have access to it again.',
    confirmDeleteNotificationTitle: 'Do you want to delete Notification {{notificationNumber}}?',
    confirmDeleteNotificationDescription: 'This action is final, if you delete this notification you will not have access to it again.',
    addNewNotification: 'Add new notification',
    scaleType: 'Scale type',
    uploadedByUser: 'Uploaded by user',
    passCriteria: 'Pass criteria',
    comparativeTableScale: 'Comparative table of scale',
    scorePercentage: 'Score %',
    approvalValue: 'Approval value',
    score: 'Score',
    uploadScale: 'Upload scale',
    scaleName: 'Scale name',
    approvalScale: 'Approval scale',
    uploadScaleAllowedFormats: 'Allowed formats: CSV and Excel',
    downloadTemplateSheet: 'Download template sheet',

    // CONTENT
    createCourse: 'Create course',
    viewTeacher: 'View teacher',
    viewTeachers: 'View teachers',
    viewStudent: 'View student',
    viewStudents: 'View students',
    days: 'days',
    noTeacherEnrolled: 'No teacher enrolled',
    noStudentEnrolled: 'No student enrolled',
    all: 'All',
    openCourse: 'Open course',
    certifiedCourse: 'Certified course',
    enrolledStudent: 'Enrolled students ({{number}})',
    activity: 'Activity',
    thisCourseIsInPreview: 'This course is in preview mode.',
    youHaveToApproveIt: 'You have to approve it to activate it.',
    approve: 'Approve',
    archiveCourse: 'Archive course',
    archiveCourseDescription1: 'Archiving the course means that the contents will no longer be available to teachers and students.',
    archiveCourseDescription2: 'Do you want to continue with the archiving process?',
    noGoBack: 'No, go back',
    yesArchiveCourse: 'Yes, archive the course',
    archivedCourse: 'Archived courses',
    archived: 'Archived',
    unarchive: 'Unarchive',
    confirmDeleteCourse: 'Do you want to delete <b>{{courseName}}?</b>',
    confirmDeleteCourseDescription: 'Deleting this course will delete all linked content and data. <br/> Are you sure you want to continue with this action?',
    confirmKeepCourseHistory: 'Do you want to keep the historical records of the people enrolled into the course?',
    keepHistoricalRecord: 'Yes, keep the historical records of the people enrolled',
    noCancel: 'No, cancel',
    yesContinue: 'Yes, continue',
    continue: 'Continue',
    courseWillBeDeletedIn24Hs: 'Your course will be deleted in the next 24hs',
    willBeDeletedIn: 'This course will be deleted in {{hour}}hrs',

    // COURSE CREATE
    newCourse: 'New course',
    editCourse: 'Edit course',
    stepTitle: 'Step {{step}}: {{title}}',
    overview: 'Overview',
    campus: 'Campus',
    courseImage: 'Course image',
    courseCoverLetter: 'Course study program',
    courseObjective: 'Course objective',
    editName: 'Edit name',
    addObjective: 'Add objective',
    imagePreview: 'Image preview',
    courseType: 'Course type',
    duration: 'Duration',
    other: 'Other',
    defineCourseDuration: 'Define the course duration (weeks)',
    defineWeekSchedule: 'Define the week schedule',
    addNewDayOfClass: 'Add new day of class',
    createCourseStructure: 'Create course structure',
    editCourseStructure: 'Edit course structure',
    nameOfFirstLevel: 'Name of first level (0)',
    nameOfLastLevel: 'Name of last level',
    activityContent: 'Activity / Content',
    addIntermediateLevel: 'Add intermediate level',
    nameOfIntermediateLevel: 'Name of intermediate level',
    structurePreview: 'Structure preview',
    assignTeacher: 'Assign teacher',

    // TEACHER LIST
    teacherTrainer: 'Teacher / Trainer',
    confirmDelete: 'Do you want to delete ',
    asTeacher: ' as teachers / trainers',
    confirmDeleteUserDescription: 'If you delete, this user will not be {{role}} for now',
    confirmDeleteUserMultipleDescription: 'If you delete, these users will not be {{role}} for now',
    confirmDeleteMultipleTeacher: 'Do you want to delete {{number}} teacher(s)?',
    career: 'Career',
    status: 'Status',
    Active: 'Active',
    inactive: 'Inactive',
    accountNotVerified: 'Account not verified',
    lastConnectionTime: 'Last time of connection {{time}}',
    selectedTeacherNumber: 'Selected {{number}} teacher(s)',
    downloadSpreadSheet: 'Download spreadsheet',
    lastnamesAndNames: 'Last name/s and name/s',
    contact: 'Contact',
    addTeacherTrainer: 'Add teacher / trainer',
    simpleLoad: 'Simple load',
    massiveLoad: 'Massive load',
    page: 'Page',
    of: 'of',
    filter: 'Filter',

    // TEACHER CREATE
    addNewTeacherTrainer: 'Add new teacher / trainer',
    generalData: 'General data',
    personalInformation: 'Personal information',
    contactInformation: 'Contact information',
    addCourse: 'Add course',
    youHaveNumberSelectedCourse: 'You have {{number}} of selected courses',
    course: 'Course',
    reference: 'Reference',
    startDate: 'Start date',
    weekSchedule: 'Week schedule',
    noWeekSchedule: 'No week schedule',
    weekScheduleTimeRange: 'from {{start_time}} to {{finish_time}}',
    endDate: 'End date',
    deadline: 'Deadline',
    rolePlayed: 'Role played',
    selectedCourse: 'Selected courses',
    role: 'Role',
    courseDuration: '{{number}} weeks',
    selectRole: 'Select role',
    function: 'Function',

    // TEACHER CREATE MASSIVE
    massiveLoadTeacherTrainer: 'Massive load of teachers / trainers',
    massiveLoadTeacherDescription: 'Welcome to the massive load of teachers. At the end of loading the form, you must enroll each professor in the corresponding study area, career or course.',
    spreadsheetLoad: 'Spreadsheet load',
    downloadCsvToComputer: 'A. Download the CSV file to your computer',
    downloadTeacherInformationAsCsv: 'Download teacher information in a CSV file',
    downloadStudentInformationAsCsv: 'Download student information in a CSV file',
    downloadBlankCsvTemplate: 'Download the blank CSV template',
    addEditUserInformationInCsv: 'B. Add or edit user information in CSV template',
    spreadsheetExample: '* Example of spreadsheet display',
    uploadNewCsvFile: 'C. Upload your new CSV file',
    attacheACsvFile: 'Attach a CSV file',
    templatePreview: 'Template preview',
    invalidData: 'Invalid data',
    duplicatedEmail: 'Duplicated email',
    duplicatedPhone: 'Duplicated phone',

    // TEACHER DETAIL
    editTeacherTrainer: 'Edit teacher / trainer',
    enrolledTeachers: 'Enrolled teachers',
    enrolledStudents: 'Enrolled students',

    // STUDENT LIST
    addStudent: 'Add student',
    confirmDeleteMultipleStudent: 'Do you want to delete {{number}} student(s)?',
    selectedStudentNumber: 'Selected {{number}} student(s)',
    asStudent: ' as students',
    finished: 'Finished',
    inProcess: 'In process',

    // STUDENT CREATE
    addIndependentCourse: 'Add independent courses',
    wantAssignIndependentCourse: 'Do you want to assign an independent course?',
    independentCourse: 'Independent course',
    courseSelectedNumber: 'You have selected {{number}} courses',
    sendAccountVerificationEmail: 'Send account verification email to student',
    sendAccountVerificationEmailTeacher: 'Send account verification email to teacher',

    // STUDENT CREATE MASSIVE
    massiveLoadStudent: 'Massive load of student',
    massiveLoadStudentDescription: 'Welcome to the massive load of students. Following the steps, you will be able to register a list of students within the same study area, career or individual course.',

    // STUDENT DETAIL
    editStudent: 'Edit student',
    studentEnrolledToIndependentCourse: 'This student is enrolled in {{number}} independent courses',

    // ACCOUNT PAGE
    generalInformation: 'General information',
    summery: 'Biography',
    security: 'Security',

    profilePhoto: 'Profile photo',
    change: 'Change',
    selectFile: 'Select file',
    allName: 'Name/s and last name/s',
    gender: 'Gender',
    residenceCity: 'City of residence',
    residenceState: 'State of residence',
    residenceCountry: 'Country of residence',
    timezone: 'Timezone',
    identification: 'Identification',
    passport: 'Passport',
    genderundefined: 'Unknown',
    gender0: 'Other',
    gender1: 'Male',
    gender2: 'Female',
    addNationality: 'Add nationality',
    cancel: 'Cancel',
    save: 'Save',
    accounts: 'Accounts',
    mainEmail: 'Main e-mail',
    otherEmail: 'Other e-mail',
    mainPhone: 'Main mobile',
    otherPhone: 'Other mobile',
    whatsapp: 'Whatsapp',
    telegram: 'Telegram',
    telegramAccount: 'Telegram account',
    verifyAccount: 'Verify account',
    verifiedEmail: 'Everything is ok, this mail is verified',
    unVerifiedEmail: 'This email has not been verified',
    unVerifiedAccount: 'Unverified account',
    verifiedAccount: 'Verified account',
    verifiedPhone: 'Everything is ok, this number is verified',
    unVerifiedPhone: 'This number has not been verified',
    resendVerificationEmail: 'Resend verification mail',
    sendVerificationEmailButton: 'Send verification mail',
    sendVerificationCodeButton: 'Send verification code',
    resendVerificationMessage: 'Resend verification message',
    addEmail: 'Add email',
    addPhone: 'Add mobile',
    verifyTelegramAccount: 'Verify telegram account',
    stepNumber: 'Step {{step}}',
    telegramVerificationStep1: 'Enter telegram',
    telegramVerificationStep2_1: 'Go to the following link',
    telegramVerificationStep2_2: 'or search for the user',
    telegramVerificationStep3: 'Send by this means the email with which you are registered in the system',
    enterVerificationCode: 'Enter verification code',
    makeSureDigitCode: 'Make sure you receive 6 digit code',
    resendCode: 'Resend code',
    coverLetter: 'Bio',
    tagTitle: 'I\'m interested',
    userLanguageTitle: 'Languages',
    mainUserLanguage: 'Main language',
    otherUserLanguage: 'Other language',
    changePassword: 'Change password',


    // UBOT HOME
    ubotHome: 'Ubot home',
    noInstitution: 'You don\'t have any institution yet',
    noInstitutionConfiguration: 'You don\'t have any institution to configure',
    completeProfileMessage: 'Remember to complete your profile here :)',
    homeCardTitle1: 'Want to study something?',
    homeCardTitle2: 'Want to teach?',
    homeCardTitle3: 'Create a new institution',
    homeCardSubTitle1: 'Open courses',
    homeCardButton1: 'Search courses',
    homeCardButton2: 'Create a course',
    homeCardButton3: 'Create institution',

    // UBOT INSTITUTION CREATE
    backToHome: 'Back to home',
    choosePlanTitle: 'Let\'s start finding the perfect plan for you!',
    choosePlanDescription: 'Please, select some options below so Smarty could help you to find an ideal plan for your needs.',
    searchMyPlan: 'Search my plan',
    searchAgain: 'Search again',
    customizePlanTitle: 'Customize your plan',
    confirmPlanTitle: 'Confirm your plan',
    confirmPlanDescription: 'Please, give us some information about your institution',
    institutionInformation: 'Institution information',
    billingAddress: 'Billing address',
    confirmPlan: 'Confirm plan',
    institutionSuccessEmailTitle: 'Thanks for creating an institution with us!',
    institutionSuccessEmailDescription: 'You will be receiving a verification email with the payment details',
    perMonth: 'P/month',
    requestDemo: 'Request a Demo',
    freePlan: 'Free plan',
    requestDemoDescription: 'Select this option to create a specific demo for you',
    customizePlan: 'Customize plan',
    customizePlanDescription: 'Select this option to create a specific demo for you',
    RECOMMENDED_PLAN: 'RECOMMENDED PLAN',
    DEMO_PLAN: 'DEMO PLAN',
    FREE_PLAN: 'FREE PLAN',
    CUSTOMIZE_PLAN: 'CUSTOMIZE PLAN',
    selectPlan: 'Select plan',
    editPlan: 'Edit plan',

    currency: 'Currency',
    currency_usd: 'USD U.S. Dollar',
    currency_clp: 'CLP Chilean Peso',
    currency_ars: 'ARS Argentine Peso',
    currency_brl: 'BRL Brazilian Real',

    productDescription_institution_course_number: 'Maximum {{number}} courses',
    productDescription_institution_student_number: 'Maximum {{number}} students',
    productDescription_use_telegram: 'Use Telegram',
    productDescription_use_whatsapp: 'Use WhatsApp',
    productDescription_course_student_number: 'Maximum {{number}} students per course',
    productDescription_storage: '{{number}}GB saved data',
    productDescription_other_platform: 'Integration other platforms',
    productDescription_access_analytics: 'Access to analytics',
    productDescription_access_communication: 'Access to communication',
    productDescription_survey_number: 'Maximum {{number}} surveys',
    productDescription_workspace_number: 'Maximum {{number}} workspaces',
    productDescription_message_number: 'Maximum {{number}} messages',

    sectorName_education: 'Education',
    sectorName_business: 'Company / Business',
    sectorName_ngo: 'NGO / Foundation',
    sectorName_go: 'Government institution',
    sectorName_person: 'Independent teacher / professor',
    noSubSector: 'No sub sector',

    subSectorName_public_university: 'Public university',
    subSectorName_private_university: 'Private university',
    subSectorName_public_tertiary_education: 'Public tertiary education',
    subSectorName_private_tertiary_education: 'Private tertiary education',
    subSectorName_public_college: 'Public college/high school',
    subSectorName_private_college: 'Private college/high school',
    subSectorName_institution: 'Institute',

    subSectorName_farming: 'Farming; plantations, other rural sectors',
    subSectorName_feeding: 'Feeding; drinks; tobacco',
    subSectorName_commerce: 'Commerce',
    subSectorName_construction: 'Construction',
    subSectorName_education: 'Education',
    subSectorName_transport_manufacture: 'Manufacture of transport equipment',
    subSectorName_public_function: 'Public function',
    subSectorName_hotel_restoraunt: 'Hotels, restaurants, tourism',
    subSectorName_chemical_industry: 'Chemical industries',
    subSectorName_medical_electrical_engineering: 'Mechanical and electrical engineering',
    subSectorName_media_culture: 'Media, culture; graphics',
    subSectorName_mining: 'Mining (coal, other mining)',
    subSectorName_oil_gas_production: 'Oil and gas production; oil refining',
    subSectorName_base_metal_production: 'Base metal production',
    subSectorName_postal_telecommunication_service: 'Postal and telecommunications services',
    subSectorName_health_service: 'Health services',
    subSectorName_financial_service: 'Financial services; professional services',
    subSectorName_utilities: 'Utilities (water; gas; electricity)',
    subSectorName_forestry: 'Forestry; wood, cellulose; paper',
    subSectorName_transportation: 'Transportation (including civil aviation; railways; road transportation)',
    subSectorName_marine_transport: 'Marine transport; ports, fishing; internal transport',

    // UBOT INSTITUTION DASHBOARD
    admin: 'Admin',
    studentApprentice: 'Student / Apprentice',
    viewInstitution: 'View institution',
    goToAcademics: 'Go to Academics',
    statistics: 'Statistics',
    goToAnalytics: 'Go to Analytics',
    messages: 'Messages',
    goToCommunication: 'Go to Communication',
    activeCourse: 'Active courses',
    activeTeacher: 'Active teachers',
    activeStudent: 'Active students',
    courseInProgress: 'Courses in progress',
    approved: 'Approved',
    notApproved: 'Not approved',
    toQualify: 'To qualify',
    qualified: 'Qualified',
    completed: 'Completed',
    activityDelivered: 'Activities delivered',
    ratedActivities: 'Rated activities',
    disapproved: 'Disapproved',
    courseEscape: 'Dropout rate',
    qualification: 'Qualification',
    gradeByScale: 'Grade by scale',
    youHaveUncheckedMessage: 'You have {{ number }} unchecked message',
    youHaveUnsentSurvey: 'You have {{ number }} surveys without sending',
    youHaveNoCourse: 'You don\'t have any courses here.',

    // UBOT INSTITUTION CONFIGURATION
    institutionInfo: 'Institution info',
    ownerAdmin: 'Owner / Administrators',
    plan: 'Plan',
    institutionType: 'Institution type',
    schedule: 'Schedule',
    institutionImage: 'Institution image',
    subOffice: 'Sub-offices',
    addOffice: 'Add new office',
    owner: 'Owner',
    changeOwner: 'Change owner',
    youAreOwner: 'You are the owner of this institution',
    admins: 'Administrators',
    firstnames: 'Name/s',
    lastnames: 'Surname/s',
    product: 'Products',
    addAdmin: 'Add administrator',
    newAdmin: 'New administrator',
    yourPlan: 'Your plan',
    changeYourPlan: 'Change your plan',
    contactPhone: 'Contact phone number',
    noSubOffice: 'No sub office',
    noData: 'No data',
    confirmGiveOwnerAccess: 'Are you sure to give the owner access to',
    confirmDeleteAdmin: 'Are you sure to delete {{name}} as administrator?',
    giveOwnerAccessDescription1: 'If you give access, you will be an Administrator.',
    giveOwnerAccessDescription2: 'You can only regain owner if someone assigns you.',
    confirmDeleteAdminDescription: 'If you delete, this user will not be an admin any more.',
    selectProduct: 'Select product',
    categoryName_academic: 'Academics',
    categoryName_analytics: 'Analytics',
    categoryName_communication: 'Communication',
    selectAll: 'Select all',
    permissionName_create_career: 'Create career',
    permissionName_create_approve_course: 'Create and approve course',
    permissionName_calendar_broadcast: 'Calendar and broadcast list',
    permissionName_access_analytics: 'Access to analytics',
    permissionName_access_communication: 'Access to Communications',
    permissionName_chat_teacher: 'Chat with teacher',
    permissionName_chat_student: 'Chat with student',
    permissionName_send_survey_template: 'Send survey and template',

    // CHAT
    students: 'Students',
    administrator: 'Administrator',
    broadcastList: 'Broadcast list',
    message: 'Message',
    smarty: 'Smarty',
    groups: 'Groups',
    hello: 'Hello!',
    youCanSelectConversationFromInbox: 'You can select a conversation from then inbox.',
    links: 'Links',
    contents: 'Contents',
    smartyWhatsapp: 'Smarty WhatsApp',
    smartyTelegram: 'Smarty Telegram',
    smartyAnswering: 'Smarty is currently answering this chat',
    stopChatbot: 'STOP CHATBOT',
    surveyAnswering: 'This student is currently answering a survey, please try again later.',
    adminAnswering: 'An admin is currently answering to this student, please try again later.',
    teacherAnswering: 'Another teacher is currently answering to this student, please try again later.',
    activateChatbot: 'ACTIVATE CHATBOT',
    smartyWillBeActivated: 'Smarty will be activated automatically in {{min}} mins',
    editTemplate: 'Edit template',
    whatsappLimitTitle: 'You have reached your limit of 24 hours on WhatsApp',
    whatsappLimitDescription: 'There is a 24-hour window to chat with a customer without paying additional service fees. You can keep in touch by sending an approved WhatsApp template message.',
    customFiled: 'Custom field',
    send: 'Send',
    groupFiles: 'Group files',
    addFileLink: 'Add file / link',
    mediaFile: 'Media file',
    addLink: 'Add link',
    upload: 'Upload',
    addMediaFile: 'Add media file',
    attachFile: 'Attach a file',
    textAndMultimediaFile: 'Text and multimedia files',
    newBroadcastList: 'New broadcast list',
    reasonForMessage: 'Reason for message',
    describeMessageToNotify: 'Describe the message to notify',
    publication: 'Publication',
    immediate: 'Immediate',
    scheduled: 'Scheduled',
    recipients: 'Recipients',
    addNewCourse: 'Add new course',
    courseReference: 'Course / Reference',
    indicateWhoReceiveMessage: 'Indicate who you want to send the message to',
    addGroups: 'Add groups',
    previewMessage: 'Preview message',
    showMessagePreview: 'Show message preview',
    hideMessagePreview: 'Hide message preview',
    previewMode: 'Preview mode',
    broadcastPreviewParameterDescription: '<b>Important: Student name </b> and <b>Teacher name </b> are variables automatically completed by system.',
    noMessageTemplate: 'No message template',
    studentName: 'Student name',
    teacherName: 'Teacher name',
    myBroadcast: 'My broadcast',
    deletedUser: 'Deleted user',

    // MY COURSE
    courseStatus0: 'Active courses',
    "courseStatus-1": 'Draft courses',
    courseStatus1: 'Finished courses',
    activities: 'Activities',
    editStructure: 'Edit structure',
    duplicate: 'Duplicate',
    archive: 'Archive',
    editStructureMode: 'Edit structure mode',
    addNewContent: 'Add new content',
    newItem: 'New item',
    newActivity: 'New activity',
    editActivity: 'Edit activity',
    createActivityStepSelectActivityType: 'Step 1: Select activity type',
    createActivityStepAddNew1: 'Step 2: Add new class',
    createActivityStepAddNew2: 'Step 2: Add new task',
    createActivityStepAddNew3: 'Step 2: Add new evaluation',
    createActivityStepAddNew4: 'Step 2: Add new workshop',
    createActivityStepAddNew7: 'Step 2: Add new theory',
    createActivityStepCharacter1: 'Step 3: Define the characteristics of the class',
    createActivityStepCharacter2: 'Step 3: Define the characteristics of the task',
    createActivityStepCharacter3: 'Step 3: Define the characteristics of the evaluation',
    createActivityStepCharacter4: 'Step 3: Define the characteristics of the workshop',
    createActivityStepUploadContent1: 'Step 4: Upload class content',
    createActivityStepUploadContent2: 'Step 4: Upload task content',
    createActivityStepUploadContent3: 'Step 4: Upload evaluation content',
    createActivityStepUploadContent4: 'Step 4: Upload workshop content',
    createActivityStepUploadContent7: 'Step 3: Upload theory content',
    createActivityStepSelectActivityTypeDescription: 'Select the type of activity you want to upload',
    activityTypeName1: 'Class',
    activityTypeName2: 'Task',
    activityTypeName3: 'Evaluation',
    activityTypeName4: 'Workshop',
    activityTypeName5: 'Survey',
    activityTypeName6: 'Simulator',
    activityTypeName7: 'Theory',
    mandatory: 'Mandatory',
    notMandatory: 'Not mandatory',
    selectOneOrBoth: 'Select one or both options',
    inPerson: 'In person',
    virtual: 'Virtual',
    faceToFaceRoom: 'Face-to-face room',
    onlineRoom: 'Online room',
    addNewLink: 'Add new link',
    textMultimediaFiles: 'Text and multimedia files',
    uploadFiles: 'Upload files',
    individual: 'Individual',
    group: 'Group',
    withQualification: 'With qualification',
    withoutQualification: 'Without qualification',
    uploadFileTypeDocument: 'Text documents (doc, docx, pdf)',
    uploadFileTypeMedia: 'Images / video (jpg, avi, mp4)',
    uploadFileTypePresentation: 'Presentation (ppt, pptx)',
    noFile: 'No file',
    noLink: 'No link',
    youAreAlmostDone: 'You\'re almost done!',
    createActivityPopUpDescription: 'We are going to post a preview of the activity. Check that everything is OK and publish it',
    noActivity: 'No activities',
    activityCharacter: 'Activity characteristics',
    localOnlineTypeText1: 'Face-to-face',
    localOnlineTypeText2: 'Virtual',
    localOnlineTypeText3: 'Virtual or face-to-face',
    activityRoom: 'Activity room',
    easyLMSInfo: 'Easy LMS info',
    easyLMSExam: 'Easy LMS exam',
    liveActivity: 'Live activity',
    location: 'Location',
    link: 'Link',
    files: 'Files',
    publish: 'Publish',
    'activityStatusText-1': 'To start',
    'activityStatusText0': 'In process',
    'activityStatusText1': 'Finished',
    backToDraft: 'Back to draft',
    delivered: 'Delivered',
    rating: 'Rating',
    note: 'Note',
    snote: 'note',
    attendance: 'Attendance',
    startActivity: 'Start activity',
    notDelivered: 'Not delivered',
    onTime: 'On time',
    outOfTime: 'Out of time',
    present: 'Present',
    absence: 'Absence',
    absent: 'Absent',
    noGroup: 'No group',
    uploadYourContent: 'Upload your content',
    textAndMultimediaFiles: 'Text and multimedia files',
    cannotRemoveCourseContent: 'You cannot delete or move this unit because it is in progress',
    syncBlackboard: 'Sync blackboard',
    syncAdminClass: 'Sync AdminClass',

    // COURSE INFO
    courseCharacter: 'Course characteristics',
    teachers: 'Teachers',
    courseStudyProgram: 'Course study program',

    // GROUP
    addNewGroup: 'Add new group',
    createGroup: 'Create group',
    groupName: 'Group name',
    groupActivities: 'Group activities',
    selectGroupActivities: 'Select group activities',
    notSelectActivityYet: 'Don\'t select activity yet',
    groupImage: 'Group image',
    groupImageOption1: 'Upload file from your computer ({{size}}MB max)',
    groupImageDescription1: 'The image format must be JPG or PNG and be larger than 250px x 250px.',
    groupImageOption2: 'Upload file from link',
    groupImageOption3: 'Use default image',
    members: 'Members',
    addNewMembers: 'Add new members',
    changeResponsible: 'Change responsible',
    minimumNumberOfStudents: 'Minimum number of students',
    maximumNumberOfStudents: 'Maximum number of students',
    selectResponsible: 'Select responsible',
    unselect: 'Unselect',
    responsible: 'Responsible',
    noMember: 'No member',
    groupLockIconDescription: 'Lock / unlock group. Students will not be able to edit it while it is locked.',
    recentActivity: 'Recent activity',
    otherGroups: 'Other groups',
    join: 'Join',
    viewGroup: 'View group',
    membershipRequest: 'Membership request',
    joinRequest: '{{name}} wants to join the group',
    acceptRequest: 'Accept request',
    enteredAt: 'Entered into group on {{datetime}}',
    leftAt: 'Left the group on {{datetime}}',
    sendMessage: 'Send message',
    editGroup: 'Edit group',
    deleteGroup: 'Delete group',
    confirmDeleteGroupTitle: 'Are you sure to delete {{name}}?',
    confirmDeleteGroupDescription: 'This action is definitive, if you delete the group you will no longer have access to it or its content.',
    you: "You",
    groupActionDescription1: '{{userName}} created {{groupName}}.',
    groupActionDescription2: '{{userName}} asked to join into {{groupName}}.',
    groupActionDescription3: '{{userName}} joined into {{groupName}}.',
    groupActionDescription4: '{{userName}} left from {{groupName}}.',
    awaitingAcceptance: 'Awaiting acceptance',
    studentNotAllowedInGroupActivity: 'This student already has a group with the same activities',
    youAreInOtherGroupWithThisActivity: 'You are in another group for these activities',
    alreadyDeliveredActivity: 'This activity was already delivered',
    memberNumberOverflow: 'This group already have maximum number of members',

    // EVALUATION
    qualifications: 'Qualifications',
    history: 'History',
    rateAll: 'Rate all',
    rateActivity: 'Rate activity',
    viewActivity: 'View activity',
    allActivities: 'All activities',
    superiorLevels: 'Superior levels',
    assessment: 'Assessment',
    assessmentOfExtraActivities: 'Assessment of extra activities',
    groupActivity: 'Group activity',
    average: 'Average',
    equivalentGradeByScale: 'Equivalent grade by scale',
    studentStatus: 'Student status',
    individualQualification: 'Individual qualification',
    groupQualification: 'Group qualification',
    comments: 'Comments',
    deliveredContent: 'Delivered contents',
    activityStatus: 'Activity status',
    equivalentMarks: 'Equivalent marks',
    ratingPercentage: 'Rating percentage',
    equivalentScaleNote: 'Equivalent on the scale to note {{number}}',
    recoveryOfActivity: 'Recovery of activity',
    recoveryActivityDescription: 'Indicates if this activity can be recovered',
    activityCanBeRecovered: 'Yes, it can be recovered',
    activityCannotBeRecovered: 'No it cannot be recovered',
    qualify: 'Qualify',
    youRated: 'You rated {{name}}',
    thisActivityCanBeRecovered: 'This activity can be recovered',
    thisActivityCannotBeRecovered: 'This activity cannot be recovered',
    editQualification: 'Edit qualification',
    qualifyIndividually: 'Qualify individually',
    rateGroup: 'Rate group',
    grade: 'Grade',
    delivery: 'Delivery',
    deliver: 'Deliver',
    viewDelivery: 'View delivery',
    courseProgress: 'Course progress: {{percent}}% completed',

    // ALERT
    alerts: 'Alerts',
    settings: 'Settings',
    enableAlert: 'Enable',
    educationalAlerts: 'Educational alerts',
    alertType_dashboard: 'Dashboard',
    alertType_platform_interact_teacher: 'Inactivity with platforms',
    alertType_platform_interact_student: 'Inactivity with platforms',
    alertType_qualification_time: 'Qualification time',
    alertType_performance: 'Performance',
    alertType_pass_rate: 'Pass rate',
    alertType_activity_delivery_status: 'Activity deliver status',
    alertType_duoc_online: 'Duoc online',
    teachersSettings: 'Teachers settings',
    studentsSettings: 'Students settings',
    earlyWarning: 'Early warning',
    finalWarning: 'Final warning',
    settingFormLabel_platform_interact_teacher_early: 'Percentage of inactivity',
    settingFormLabel_platform_interact_teacher_final: 'Percentage of inactivity',
    settingFormLabel_platform_interact_student_early: 'Percentage of inactivity',
    settingFormLabel_platform_interact_student_final: 'Percentage of inactivity',
    settingFormLabel_qualification_time_early: 'Qualification time on days',
    settingFormLabel_qualification_time_final: 'Qualification time on days',
    settingFormLabel_performance_early: 'Average of qualification percentage',
    settingFormLabel_performance_final: 'Average of qualification percentage',
    settingFormLabel_pass_rate_early: 'Percentage of non-pass activities',
    settingFormLabel_pass_rate_final: 'Percentage of non-pass activities',
    settingFormLabel_activity_delivery_status_early: 'Percentage of out-of-time-deliver activities',
    settingFormLabel_activity_delivery_status_final: 'Percentage of non-deliver activities',
    alertDescription_platform_interact_student: 'The alert shows the percentage of students who have not interacted with platforms.',
    alertDescription_platform_interact_teacher: 'The alert shows the percentage of teachers who have not interacted with platforms.',
    alertDescription_qualification_time: 'The alert shows the average time that the teachers spend on qualify an activity after the deliver time per course.',
    alertDescription_performance: 'The alert shows the qualification average in percentage and note that have the students on a course, considering all the activity types:',
    alertDescription_pass_rate: 'The alert shows the average of non-pass rate that have the students on a course, considering all the activity types:',
    alertDescription_delivery_status: 'The alert shows the percentage of delivers of students with status of:',
    nonInteractionWarning: 'Non-interaction warnings',
    warningAlerts: 'Warning alerts',
    globalAverageOfNonInteraction: 'Global average of non-interaction',
    globalAverageOfQualificationTime: 'Global average of Qualification time',
    globalAverageOfQualificationPercentage: 'Global average of qualification percentage',
    totalNumberCourseAnalyzed: 'Total number of course analyzed',
    totalNumberOfUsersOnAlert: 'Total number of users on alert',
    totalNumberOfMessagesNotificationsSent: 'Total number of messages notifications sent',
    sendAlertMessageTo: 'Send alert message to',
    allStudents: 'All students',
    allTeachers: 'All teachers',
    studentsWithEarlyWarning: 'Students with early warning',
    teachersWithEarlyWarning: 'Teachers with early warning',
    studentsWithFinalWarning: 'Students with final warning',
    teachersWithFinalWarning: 'Teachers with final warning',
    platform: 'Platform',
    alertMessage: 'Alert message',
    platformName_1: 'Ubot platform',
    platformName_2: 'Blackboard',
    noInteractAlertDataDescription: 'It is not possible to take the required data from this platform.',
    notInteractedDescription_platform_interact_student: 'of students have not interacted',
    notInteractedDescription_platform_interact_teacher: 'of teachers have not interacted',
    performanceAverageDescription: 'percentage of qualification average',
    messagePreview: 'Message preview',
    selectStudentWarning: 'Please select students with early or final warning to see the message preview.',
    noTemplate: 'No message template',
    sentAt: 'Sent at',
    qualificationTimeWarning: 'Course correction time longer than expected',
    qualificationTimeCorrect: 'Correct course qualification time',
    nonPassPercentage: '{{percentage}}% of non-pass',
    globalAverageOfNonPassActivity: 'global average of non-pass activities',
    nonPassAverageDescription: 'Non-pass activities',
    nonDeliveryAverageDescription: 'Non-deliver activities',
    notDeliveryPercentage: '{{percentage}}% not delivers',
    outOfTimeDeliveryPercentage: '{{percentage}}% delivers out of time',
    globalAverageOfNotDeliverActivities: 'Global average of not-deliver activities',
    allCourses: 'All courses',
    activitiesPassRate: 'Activities pass rate',
    typeOfActivity: 'Type of activity',
    numberOfActivities: 'Number of activities',
    ratingDistribution: 'Rating distribution',
    numberOfStudents: 'Number of students',
    averageInPercentage: 'Average in percentage',
    deactivateWhatsappAlert: 'Deactivate the automatic sending of alerts by WhatsApp',
    syncAll: 'Sync all',
    syncAllButtonTooltip: 'Automatic synchronization is performed every 30 minutes. Click this button if you want to sync now.',
    downloadAsExcel: 'Download as Excel',
    interactionWithPlatform: 'Interaction with course',
    ponderationActivities: 'Average summative activities',
    percentageOfDeliveries: 'Percentage of deliveries',
    DUOCOnlineAlertStatus1: 'Green1',
    DUOCOnlineAlertStatus2: 'Yellow1',
    DUOCOnlineAlertStatus3: 'Yellow2',
    DUOCOnlineAlertStatus4: 'Yellow3',
    DUOCOnlineAlertStatus5: 'Red1',
    DUOCOnlineAlertStatus6: 'Red2',
    DUOCOnlineAlertStatus7: 'Red3',
    DUOCOnlineAlertStatus8: 'Red4',
    courseTotalDays: 'Course total days',
    updateInformation: 'Update information',
    process_started_title: 'Warning',
    process_started: 'Your request has been received. The process has started and will take a few minutes.',

    // STATISTICS
    alertAndStatistics: 'Alert & statistics',
    activityRating: 'Activity rating',
    totalNumberOfActivities: 'Total number of activities',
    qualifiedActivities: 'Qualified activities',
    activitiesToBeGraded: 'Activities to be graded',
    recover: 'Recover',
    undelivered: 'Undelivered',
    tests: 'Tests',
    otherActivities: 'Other activities',
    courseAlert_studentsNotInteracted: 'of students have not interacted',
    courseAlert_qualificationAverage: 'qualification average',
    courseAlert_nonDeliverActivities: 'non-deliver activities',
    deliversOutOfTime: 'delivers out of time',
    notDelivers: 'not delivers',
    ofNonPass: 'of non-pass',
    courseAlert_courseCorrectionTime: 'Course correction time longer than expected',
}