import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import App from './App';

import reportWebVitals from './reportWebVitals';
import rootReducer from './service/store/reducer';
import './translations/i18n';

import 'flag-icon-css/css/flag-icon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-multi-carousel/lib/styles.css";

// Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://b56bcd5e91974c5194c18e874ba86659@sentry.ftsdn.com/19",
    integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0.25,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_PROJECT_ENV ? process.env.REACT_APP_PROJECT_ENV : 'local',
});
  
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function(registration) {
            console.log("Service worker registration successful, scope is:", registration.scope);
        })
        .catch(function(err) {
            console.log("Service worker registration failed, error:", err);
        });
}

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
