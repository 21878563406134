import {combineReducers} from 'redux'

import theme from './theme';
import data from './data';
import auth from './auth';
import teacher from './teacher';
import student from './student';
import course from './course';
import notification from './notification';

export default combineReducers({
	theme,
	data,
	auth,
	teacher,
	student,
	course,
	notification,
})