export const SET_THEME = 'SET_THEME';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_LOADING = 'SET_LOADING';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_UBOT_TOAST = 'SET_UBOT_TOAST';
export const SET_OPEN_DROPDOWN = 'SET_OPEN_DROPDOWN';
export const SET_OPEN_MOBILE_MENU = 'SET_OPEN_MOBILE_MENU';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_INSTITUTION_DATA = 'UPDATE_USER_INSTITUTION_DATA';
export const UPDATE_CURRENT_USER_INSTITUTION = 'UPDATE_CURRENT_USER_INSTITUTION';
export const UPDATE_INSTITUTION = 'UPDATE_INSTITUTION';

export const UPDATE_TEACHER_DATA = 'UPDATE_TEACHER_DATA';
export const UPDATE_TEACHER_FILTER = 'UPDATE_TEACHER_FILTER';

export const UPDATE_ALL_STUDENTS = 'UPDATE_ALL_STUDENTS';
export const UPDATE_STUDENT_DATA = 'UPDATE_STUDENT_DATA';
export const UPDATE_STUDENT_FILTER = 'UPDATE_STUDENT_FILTER';

export const UPDATE_CAMPUS_DATA = 'UPDATE_CAMPUS_DATA';
export const UPDATE_CAREER_DATA = 'UPDATE_CAREER_DATA';
export const UPDATE_SCALE_DATA = 'UPDATE_SCALE_DATA';
export const UPDATE_COURSE_CATEGORY_DATA = 'UPDATE_COURSE_CATEGORY_DATA';
export const UPDATE_WORKSPACE_DATA = 'UPDATE_WORKSPACE_DATA';
export const UPDATE_ADMIN_DATA = 'UPDATE_ADMIN_DATA';
export const UPDATE_TEMPLATE_DATA = 'UPDATE_TEMPLATE_DATA';
export const UPDATE_ALERT_TEMPLATE_DATA = 'UPDATE_ALERT_TEMPLATE_DATA';
export const UPDATE_GROUP_DATA = 'UPDATE_GROUP_DATA';

export const UPDATE_COURSE_DATA = 'UPDATE_COURSE_DATA';
export const UPDATE_CURRENT_COURSE = 'UPDATE_CURRENT_COURSE';

export const UPDATE_NOTIFICATION_DATA = 'UPDATE_NOTIFICATION_DATA';
export const UPDATE_BROADCAST_DATA = 'UPDATE_BROADCAST_DATA';
export const UPDATE_ALERT_DATA = 'UPDATE_ALERT_DATA';
export const UPDATE_ALERT_TAB = 'UPDATE_ALERT_TABb';