import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Spinner from 'react-spinkit';

const propTypes = {
    color: PropTypes.string,
};

const Loader = ({color}) => {

    return (
        <Wrapper>
            <Spinner name="double-bounce" color={!!color ? color : "grey"}/>
        </Wrapper>
    )
};

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
`;

Loader.propTypes = propTypes;

export default Loader