import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
	userData: null,
	userInstitutionData: null,
	currentUserInstitution: null,
	institutionData: null,
};

const updateUserData = (state, action) => {
	return updateState(
		state,
		{
			userData: action.userData
		}
	)
};

const updateUserInstitutionData = (state, action) => {
	return updateState(
		state,
		{
			userInstitutionData: action.userInstitutionData
		}
	)
};

const updateCurrentUserInstitution = (state, action) => {
	return updateState(
		state,
		{
			currentUserInstitution: action.currentUserInstitution,
		}
	)
};

const updateInstitution = (state, action) => {
	return updateState(
		state,
		{
			institutionData: action.institutionData,
		}
	)
};

const reducer = (state=initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_USER_DATA: return updateUserData(state, action);
		case actionTypes.UPDATE_USER_INSTITUTION_DATA: return updateUserInstitutionData(state, action);
		case actionTypes.UPDATE_CURRENT_USER_INSTITUTION: return updateCurrentUserInstitution(state, action);
		case actionTypes.UPDATE_INSTITUTION: return updateInstitution(state, action);
		default: return state;
	}
};

export default reducer;