import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    allStudents: null,
    students: null,
    count: 0,
    filter: {
        page: 1,
        text: null,
        course: null,
        status: null,
    },
};

const updateAllStudents = (state, action) => {
    return updateState(
        state,
        {
            allStudents: action.allStudents,
        }
    )
};

const updateStudentData = (state, action) => {
    return updateState(
        state,
        {
            students: action.students,
            count: action.count,
        }
    )
};

const updateFilterObj = (state, action) => {
    return updateState(
        state,
        {
            filter: {
                ...state.filter,
                ...action.filter
            }
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ALL_STUDENTS: return updateAllStudents(state, action);
        case actionTypes.UPDATE_STUDENT_DATA: return updateStudentData(state, action);
        case actionTypes.UPDATE_STUDENT_FILTER: return updateFilterObj(state, action);
        default: return state;
    }
};

export default reducer;