import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    notificationData: null,
    broadcastData: null,
    alertData: null,
    customAlertData: null,
    extra_data: {
        scaleList: [],
    }
};

const updateNotificationData = (state, action) => {
    return updateState(
        state,
        {
            notificationData: action.notificationData
        }
    )
};

const updateBroadcastData = (state, action) => {
    return updateState(
        state,
        {
            broadcastData: action.broadcastData
        }
    )
};

const updateAlertData = (state, action) => {
    return updateState(
        state,
        {
            alertData: action.alertData,
            customAlertData: action.customAlertData,
        }
    )
};

const updateAlertTab = (state, action) => {
    return updateState(
        state,
        {
            alertData: action.alertData,
            extra_data: {
                ...state.extra_data,
                ...(action.extra_data || {}),
            },
        }
    );
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_NOTIFICATION_DATA: return updateNotificationData(state, action);
        case actionTypes.UPDATE_BROADCAST_DATA: return updateBroadcastData(state, action);
        case actionTypes.UPDATE_ALERT_DATA: return updateAlertData(state, action);
        case actionTypes.UPDATE_ALERT_TAB: return updateAlertTab(state, action);
        default: return state;
    }
};

export default reducer;