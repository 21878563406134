export const TRANSLATIONS_ES = {
    // ERROR MESSAGE
    INTERNAL_SERVER_ERROR: 'Error de servidor interno',
    UNAUTHORIZED: 'No autorizado',
    TOKEN_EXPIRED: 'Token caducado',
    NOT_FOUND: 'Servicio no encontrado',
    METHOD_NOT_ALLOWED: 'Método de solicitud no permitido',
    USER_DUPLICATED: 'Usuario duplicado',
    BAD_REQUEST: 'Solicitud incorrecta',
    USER_NOT_FOUND: 'Usuario no encontrado',
    PHONE_REQUIRED: 'Requiere telefono',
    PHONE_DUPLICATED: 'Número de teléfono duplicado',
    EMAIL_REQUIRED: 'Correo electronico requerido',
    EMAIL_DUPLICATED: 'Correo electrónico duplicado',
    PASSWORD_REQUIRED: 'Se requiere contraseña',
    WRONG_PASSWORD: 'Contraseña incorrecta',
    INVALID_USER: 'Usuario invalido',
    NOT_EMAIL_OWNER: 'Tu no eres el propietario de este correo electrónico',
    NOT_PHONE_OWNER: 'Tu no eres el propietario de este teléfono',
    EMAIL_NOT_FOUND: 'Correo electrónico no encontrado',
    PHONE_NOT_FOUND: 'Teléfono no encontrado',
    SENDING_EMAIL_ERROR: 'Envío fallido de correo electrónico',
    SENDING_MESSAGE_ERROR: 'Envío fallido de mensaje',
    NO_PERMISSION: 'No tienes permiso para hacer esta acción',
    INSTITUTION_NOT_FOUND: 'Institución no encontrada',
    ACCOUNT_NOT_VERIFIED: 'Cuenta no verificada',
    INVALID_TOKEN: 'Código inválido',
    SOMETHING_WRONG: 'Algo salió mal, por favor, ponte en contacto con soporte técnico',
    INVALID_TEACHER: 'Profesor invalido',
    INVALID_STUDENT: 'Estudiante invalido',
    DELETE_NOT_ALLOWED: 'Eliminar no permitido',
    JOIN_NOT_ALLOWED: 'Unirse no permitido',
    ACTIVITY_NOT_FOUND: 'Actividad no encontrada',
    INSTITUTION_NAME_DUPLICATED: 'La institución con el mismo nombre ya existe',
    NO_MESSAGE_TEMPLATE: 'La plantilla de mensaje no existe',
    NOT_ALLOWED_TO_DELETE: 'No se permite eliminar',

    ERROR_AVATAR_FILE_SIZE: 'Tamaño del archivo muy grande',
    ERROR_PONDERATION_VALUE: 'La suma de los valores de evaluación debe ser siempre 100',

    ACCOUNT_UPDATED_SUCCESS: 'Cuenta actualizada exitosamente',
    CONTACT_UPDATED_SUCCESS: 'Contacto actualizado exitosamente',
    UPDATE_PASSWORD_SUCCESS: 'Contraseña actualizada exitosamente',
    SEND_VERIFY_EMAIL_SUCCESS: 'Verificación exitosa del correo electrónico',
    SEND_VERIFY_CODE_SUCCESS: 'Verificación exitosa del código',
    CREATE_OWNER_SUCCESS: 'Has agregado un nuevo propietario. El nuevo propietario tiene que verificar su cuenta',
    CREATE_ADMIN_SUCCESS: 'Has agregado un/a nuevo/a administrador/a',
    DELETE_ADMIN_SUCCESS: 'Has eliminado un/a administrador/a',
    DELETE_TEACHER_SUCCESS: 'Has eliminado un profesor',
    DELETE_TEACHER_MULTIPLE_SUCCESS: 'Has eliminado a {{number}} profesores',
    CREATE_COURSE_SUCCESS: 'Ha creado un curso, debe esperar la aprobación del administrador',
    DELETE_STUDENT_MULTIPLE_SUCCESS: 'Has eliminado a {{number}} alumnos',
    DELETE_STUDENT_SUCCESS: 'Has eliminado un estudiante',
    UPLOAD_TEACHER_MASSIVE_SUCCESS: 'La carga se está ejecutando en segundo plano, recibirá una notificación una vez completada',
    CREATE_SCALE_SUCCESS: '¡Ha creado una nueva báscula con éxito!',
    CREATE_TEACHER_SUCCESS: '¡Has creado un profesor con éxito!',
    CREATE_STUDENT_SUCCESS: '¡Has creado un estudiante con éxito!',
    CREATE_ACTIVITY_SUCCESS: '¡Has cargado correctamente {{activityType}} {{activityName}}!',
    CREATE_BROADCAST_SUCCESS: 'Ha creado una lista de difusión con éxito',
    DUPLICATE_COURSE_SUCCESS_ADMIN: 'Has duplicado un curso, ahora debes aprobarlo',
    DUPLICATE_COURSE_SUCCESS_TEACHER: 'Ha duplicado un curso, debe esperar la aprobación del administrador',
    ARCHIVE_COURSE_SUCCESS: 'Ha archivado {{courseName}}',
    DELETE_COURSE_SUCCESS: 'Su curso será eliminado en 24 horas',
    SYNC_FUNCTION_IS_IN_BACKGROUND: 'La función de sincronización se está ejecutando en segundo plano, se le notificará cuando haya terminado',
    SYNC_BACKGROUND_FUNCTION_SUCCEEDED: 'La función de sincronización tuvo éxito en segundo plano, vuelve a cargar la página para ver los resultados',

    // FORM VALIDATION
    fieldRequired: 'Este campo es obligatorio',
    fieldInvalid: 'Valor no válido',
    weakPassword: 'Contraseña debil',
    fieldUnconfirmed: 'Por favor confirme',
    fieldMaxLength: 'Máxima {{length}} de caracteres',

    // FORM PLACEHOLDER
    user: 'Usuario',
    password: 'Contraseña',
    emailOrPhone: 'Dirección de correo electrónico o número de teléfono celular',
    enterCode: 'Ingrese su código',
    selectArea: 'Seleccione las areas',
    selectNativeLanguage: 'Seleccione idioma nativo',
    selectOtherLanguage: 'Seleccione otros idiomas',
    select: 'Seleccionar',
    placeholderExample: 'Ejemplo: {{text}}',
    filterStudyArea: 'Filtrar el área de estudio',
    filterCareerIndependentCourse: 'Filtrar carrera o curso independiente',
    activityDescriptionPlaceholder: 'Describe en pocas oraciones lo que el estudiante debe hacer',
    noDate: 'Sin fecha',
    noTime: 'Sin hora',
    liveVideoUrl: 'URL de video en vivo',
    fileUrl: 'URL del archivo',

    // FORM LABEL
    name: 'Primer Nombre',
    lastname: "Primer Apellido",
    secondName: "Segundo Nombre",
    secondLastname: "Segundo Apellido",
    birthday: "Fecha de Nacimiento",
    nationality: 'Nacionalidad',
    idNumber: 'Número de Identificación',
    email: 'Correo Electrónico',
    phone: 'Teléfono Celular',
    repeatPassword: 'Repita su contraseña',
    enterEmailPhone: 'Ingrese si correo electrónico o teléfono celular',
    verificationCodeToEmail: 'Un código de verificación para restaurar su contraseña fue enviado a su correo electrónico',
    enterNewPassword: 'Ingrese su nueva contraseña',
    repeatNewPassword: 'Repita su nueva contraseña',
    country: 'País',
    city: 'Ciudad',
    state: 'Estado',
    secondNationality: 'Segunda nacionalidad',
    secondIdentification: 'Segunda identificación',
    secondPassport: 'Segundo pasaporte',
    avatarOption1: "Seleccione una imagen de su computadora (máx. 1MB)",
    avatarOption2: "Cargue imagen desde link",
    avatarOption3: "Usar imagen por default",
    actual: "Actual",
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirme nueva contraseña",
    interestArea: "Áreas de interés",
    nativeLanguage: "Idioma nativo",
    otherLanguage: "Otros Idiomas",
    surname: "Apellido",
    secondSurname: "Segundo Apellido",
    address: 'Dirección',
    editImage: "Editar imagen",
    ok: "OK",
    sector: "Sector",
    subSector: "Subsector",
    numberOfStudent: "Número de estudiantes",
    noInfo: "No info",
    numberOfCourse: "Número de cursos",
    institutionName: 'Nombre de la institución',
    institutionEmail: 'Correo electrónico de la institución',
    institutionPhone: 'Número telefónico de contacto',
    dateOfOrigin: 'Fecha de creación',
    description: 'Descripción',
    googleMapLink: 'Link a Google maps',
    website: 'Sitio web',
    title: 'Título',
    buttonText: 'Botón de texto',
    buttonLink: 'Botón de link',
    studyArea: 'Áreas de estudio',
    independenceCourseCareer: 'Curso de independencia / carrera',
    filteredCourse: 'Curso filtrado',
    courseSet: 'Conjunto de cursos',
    courseImageOption1: 'Seleccione una imagen de su computadora (máx. 1MB)',
    courseImageDescription1: 'El formato de la imagen debe ser JPG o PNG y tener un tamaño superior a 300px x 150px.',
    courseImageOption2: 'Cargue imagen desde link',
    courseImageOption3: 'Usar imagen por default',
    courseCoverLetterFile: 'Seleccione un archivo de su computadora (3 MB máx.)',
    courseCoverLetterLink: 'Subir archivo desde enlace',
    courseCoverLetterFileDescription: 'El formato de archivo debe ser docx o pdf',
    selectIfOpenCourse: 'Seleccionar si es un curso abierto',
    yes: 'Si',
    no: 'No',
    totalAcademicHour: 'Horas académicas totales',
    equivalentCredit: 'Créditos equivalentes / ECTS',
    synchronous: 'Sincrónico',
    asynchronous: 'Asincrónico',
    classDay: 'Día de clase{{index}}',
    time: 'Tiempo',
    startTime: 'Hora de inicio',
    finishTime: 'Tiempo de finalización',
    fileFormat: 'Formato de archivo',
    selectLevel: 'Seleccione un nivel',
    selectOrder: 'Elije el orden',
    selectLevelOrder: 'Seleccione {{text}} orden',
    estimatedTime: 'Tiempo estimado (hrs)',
    indicateLocation: 'Indicar la ubicación',
    indicateGoogleMap: 'Indicar el enlace a Google maps',
    indicatePlatform: 'Indicar el enlace a la plataforma',
    indicateLinkTitle: 'Indique el título del enlace',
    indicateLinkOfFile: 'Indicar el enlace al archivo',
    comment: 'Comentario',
    expireDate: 'Fecha de finalización',
    expireTime: 'Hora de finalización',
    notificationDate: 'Fecha de notificación',
    notificationTime: 'Hora de notificación',
    file: 'Archivo',
    easyLMSExamId: 'ID de examen de Easy LMS',
    activeFrom: 'Activo desde',
    activeTo: 'Activo hasta',
    sendNotificationWhenMaterialBecomesAvailable: 'Enviar notificación cuando el material esté disponible',

    // WEEKDAY
    weekday0: 'Domingo',
    weekday1: 'Lunes',
    weekday2: 'Martes',
    weekday3: 'Miércoles',
    weekday4: 'Jueves',
    weekday5: 'Viernes',
    weekday6: 'Sábado',

    // SIDE MENU
    home: 'Inicio',
    statistic: 'Historia y estadística',
    dashboard: 'Mi tablero',
    setting: 'Configuración',
    content: 'Contenidos',
    teacher: 'Profesor',
    student: 'Estudiante',
    alert: 'Alertas',
    myEvaluation: 'Mis evaluaciones',
    achievement: 'Logros',
    myAchievement: 'Mi logro',
    myGroup: 'Mi grupo',
    myChat: 'Mis chat',
    myProgress: 'Mi progreso',
    myCourses: 'Mis cursos',
    myContents: 'Mi contenido',
    courseInfo: 'Información del curso',
    myEvaluations: 'Mis evaluaciones',
    historyAndStatistics: 'Historia y estadísticas',
    myGroups: 'Mis grupos',
    sidebarFooter: '© Todos los derechos reservados',
    menuDescription_dashboard: 'Ten al día el seguimiento de tus actividades Por hacer, en curso y terminado',
    menuDescription_setting: 'Personaliza la imagen de tu plataforma',
    menuDescription_courses: 'Consulta y ten al día el contenido de tu programa formativo',
    menuDescription_teacher: 'Consulta, agrega o edita la información de tus profesores',
    menuDescription_student: 'Consulta, agrega o edita la información de usuarios estudiantes',
    menuDescription_alert: 'Consulta el comportamiento educativo y rendimiento de tus usuarios',
    menuDescription_myEvaluation: 'Visualiza el rendimiento, evalúa las competencias y objetivos de las actividades de los usuarios de tu curso de manera grupal  e individual.',
    menuDescription_achievement: '¡Felicidades! en éste espacio tendrás la oportunidad de visualizar las recompensas por participación sobresaliente o destacada.',
    menuDescription_myAchievement: '¡Felicidades! en éste espacio tendrás la oportunidad de visualizar las recompensas por participación sobresaliente o destacada.',
    menuDescription_myGroup: 'Consulta, agrega o edita la información de tus grupos de estudio',
    menuDescription_myChat: 'Comunicaciones activas con smarty , profesores y estudiantes. Creación y envío de Listas de difusión.',
    menuDescription_myChat_student: 'Comunicaciones activas con smarty, profesores y estudiantes.',
    menuDescription_myProgress: 'Visualiza el progreso de tus cursos',
    menuDescription_myCourses: 'Consulta, crea o edita contenido de tus cursos',

    // STUDY AREA NAME
    studyAreaName_art_architecture_design: 'Arte, Arquitectura y Diseño',
    studyAreaName_biological_agriculture: 'Ciencias Biológicas y Agropecuarias',
    studyAreaName_economic_business: 'Ciencias Económicas, Negocios y Management',
    studyAreaName_educational: 'Ciencias de la Educación',
    studyAreaName_engineering: 'Ingenierías',
    studyAreaName_exact: 'Ciencias Exactas',
    studyAreaName_health: 'Ciencias de la Salud',
    studyAreaName_language: 'Idiomas',
    studyAreaName_social_humanity: 'Ciencias Sociales y Humanidades',
    studyAreaName_technology: 'Tecnología',
    studyAreaName_null: 'Otro',

    // COURSE USER ROLE
    courseUserRole_teacher_instructor: 'Instructor/a',
    courseUserRole_teacher_assistance: 'Profesor/a Asistente',
    courseUserRole_teacher_associate: 'Profesor/a Asociado',
    courseUserRole_teacher_professor: 'Profesor/a Titular',

    // COURSE USER PERMISSION
    courseUserPermission_manage_content: 'Crear / Cargar contenidos del curso',
    courseUserPermission_manage_activity: 'Calificar / Asistir / Comentar la actividad',
    courseUserPermission_chat: 'Chatear con el alumno',
    courseUserPermission_manage_course: 'Editar la información del curso',

    // HEADER
    notification: 'Notificacion',
    notifications: 'Notificaciones',
    makeAsReadAll: 'Marcar todas como leídas',
    makeAsRead: 'Marcar como leída',
    makeAsUnread: 'Hacer como no leído',
    deleteAll: 'Borrar todo',
    unread: 'No leídas',
    read: 'Leídas',
    goBackToUbotPlatform: 'Volver a Ubot',
    changeRole: 'Cambiar rol',
    academic: 'Académico',
    analytics: 'Analítica',
    communication: 'Comunicación',

    // NOTIFICATION DESCRIPTION
    notificationDescription_teacher_create_course_admin: '<b>{{creatorName}}</b> ha creado un nuevo curso y espera su aprobación',
    notificationAction_teacher_create_course_admin: 'IR AL CURSO',
    notificationDescription_admin_approve_course_teacher: '<b>{{creatorName}}</b> ha aprobado el curso <b>{{courseName}}</b>',
    notificationAction_admin_approve_course_teacher: 'IR AL CURSO',
    notificationDescription_admin_enroll_course_user: 'Has sido asignado como <b>{{ownerRoleName}}</b> en el curso <b>{{courseName}}</b>',
    notificationAction_admin_enroll_course_user: 'IR AL CURSO',
    notificationDescription_system_massive_teacher_admin: 'La creación masiva de <b>PROFESOR</b> se completó con éxito.',
    notificationAction_system_massive_teacher_admin: 'LISTA DE PROFESOR',
    notificationDescription_system_massive_student_admin: 'La creación masiva de <b>ESTUDIANTE</b> se completó con éxito.',
    notificationAction_system_massive_student_admin: 'LISTA DE ESTUDIANTE',

    notificationDescription_message_from_teacher: '<b>{{creatorName}}</b> te ha enviado un mensaje',
    notificationAction_message_from_teacher: 'IR AL MENSAJE',
    notificationDescription_broadcast_from_teacher: '<b>{{creatorName}}</b> te ha enviado una notificación',
    notificationAction_broadcast_from_teacher: 'IR AL MENSAJE',
    notificationDescription_course_message_from_teacher: 'Tienes un nuevo mensaje en el chat de <b>{{courseName}}</b>',
    notificationAction_course_message_from_teacher: 'IR AL MENSAJE',
    notificationDescription_message_from_admin: '<b>{{creatorName}}</b> te ha enviado un mensaje',
    notificationAction_message_from_admin: 'IR AL MENSAJE',
    notificationDescription_broadcast_from_admin: '<b>{{creatorName}}</b> te ha enviado una notificación',
    notificationAction_broadcast_from_admin: 'IR AL MENSAJE',
    notificationDescription_course_message_from_student: 'Tienes un nuevo mensaje en el chat de <b>{{courseName}}</b>',
    notificationAction_course_message_from_student: 'IR AL MENSAJE',
    notificationDescription_group_message_from_student: 'Tienes un nuevo mensaje en el chat de <b>{{groupName}}</b>',
    notificationAction_group_message_from_student: 'IR AL MENSAJE',

    notificationDescription_student_request_join_group: '<b>{{creatorName}}</b> quiero unirme al grupo <b>{{groupName}}</b>',
    notificationAction_student_request_join_group: 'A LA VISTA DE GRUPO',
    notificationDescription_student_deliver_individual_activity: '<b>{{creatorName}}</b> ha entregado una actividad para calificar',
    notificationAction_student_deliver_individual_activity: 'A LA ACTIVIDAD',
    notificationDescription_student_deliver_group_activity_to_teacher: 'El grupo <b>{{groupName}}</b> ha entregado una actividad para calificar',
    notificationAction_student_deliver_group_activity_to_teacher: 'A LA ACTIVIDAD',
    notificationDescription_student_deliver_group_activity_to_student: '<b>{{creatorName}}</b> ha entregado una actividad para calificar en el grupo <b>{{groupName}}</b>',
    notificationAction_student_deliver_group_activity_to_student: 'A LA ACTIVIDAD',
    notificationDescription_student_write_activity_comment: '<b>{{creatorName}}</b> ha escrito un nuevo comentario sobre la actividad <b>{{activityName}}</b>',
    notificationAction_student_write_activity_comment: 'AL COMENTARIO ACTIVIDAD',
    notificationDescription_teacher_write_activity_comment: '<b>{{creatorName}}</b> ha escrito un nuevo comentario sobre la actividad <b>{{activityName}}</b>',
    notificationAction_teacher_write_activity_comment: 'AL COMENTARIO ACTIVIDAD',

    notificationDescription_teacher_create_activity: '<b>{{creatorName}}</b> ha creado una nueva actividad <b>{{activityName}}</b>',
    notificationAction_teacher_create_activity: 'A LA ACTIVIDAD',
    notificationDescription_teacher_qualify_activity: '<b>{{creatorName}}</b> ha calificado la actividad <b>{{activityName}}</b>',
    notificationAction_teacher_qualify_activity: 'A LA ACTIVIDAD',
    notificationDescription_teacher_update_activity_content: '<b>{{creatorName}}</b> ha agregado nuevo contenido a la actividad <b>{{activityName}}</b>',
    notificationAction_teacher_update_activity_content: 'A LA ACTIVIDAD',

    notificationDescription_activity_expire_in_one_day: 'Recuerda que la actividad <b>{{activityName}}</b> vencerá el <b>{{datetime}}</b>',
    notificationAction_activity_expire_in_one_day: 'A LA ACTIVIDAD',
    notificationDescription_activity_notification_instantly: 'Recuerda que el <b>{{datetime}}</b> tienes la evaluación <b>{{activityName}}</b>',
    notificationAction_activity_notification_instantly: 'TO THE ACTIVITY',
    notificationDescription_activity_start_in_one_hour: 'Recuerda que la actividad <b>{{activityName}}</b> comienza en una hora',
    notificationAction_activity_start_in_one_hour: 'A LA ACTIVIDAD',
    notificationDescription_activity_start_in_one_day: 'Recuerda que la actividad <b>{{activityName}}</b> comienza mañana',
    notificationAction_activity_start_in_one_day: 'A LA ACTIVIDAD',
    
    notificationDescription_ponderation_error: 'La ponderación total en el curso <b>{{courseName}}</b> no es 100%',
    notificationAction_ponderation_error: 'A LA MESA DE PONDERACIÓN',

    notificationDescription_early_warning_performance: 'Tu rendimiento en el curso <b>{{courseName}}</b> es inferior al estipulado.',
    notificationAction_early_warning_performance: 'A MI PROGRESO',
    notificationDescription_final_warning_performance: 'Tu rendimiento en el curso <b>{{courseName}}</b> es inferior al estipulado.',
    notificationAction_final_warning_performance: 'A MI PROGRESO',
    notificationDescription_early_warning_pass_rate: 'Tu tasa de aprobación en el curso <b>{{courseName}}</b> es inferior a la estipulada.',
    notificationAction_early_warning_pass_rate: 'A MI PROGRESO',
    notificationDescription_final_warning_pass_rate: 'Tu tasa de aprobación en el curso <b>{{courseName}}</b> es inferior a la estipulada.',
    notificationAction_final_warning_pass_rate: 'A MI PROGRESO',
    notificationDescription_early_warning_activity_delivery_status: 'Has entregado muchas actividades fuera de tiempo en el curso <b>{{courseName}}</b>',
    notificationAction_early_warning_activity_delivery_status: 'A MI PROGRESO',
    notificationDescription_final_warning_activity_delivery_status: 'No habías entregado muchas actividades en el curso <b>{{courseName}}</b>',
    notificationAction_final_warning_activity_delivery_status: 'A MI PROGRESO',
    notificationDescription_early_warning_qualification_time: 'En el curso <b>{{courseName}}</b> hay un retraso en el tiempo de calificación',
    notificationAction_early_warning_qualification_time: 'AL MENÚ DE ALERTAS',
    notificationDescription_final_warning_qualification_time: 'En el curso <b>{{courseName}}</b> hay un retraso en el tiempo de calificación',
    notificationAction_final_warning_qualification_time: 'AL MENÚ DE ALERTAS',
    notificationDescription_alert_reminder_admin: 'Recuerda revisar las alertas educativas de tu institución',
    notificationAction_alert_reminder_admin: 'AL MENÚ DE ALERTAS',
    notificationDescription_alert_reminder_teacher: 'Recuerda revisar las alertas educativas de tus cursos',
    notificationDescription_celery_background_task_finished: 'Tarea en segundo plano <b>{{taskName}}</b> finalizada',

    backgroundTask_: '',
    backgroundTask_sync_blackboard_educational_alert: 'Pizarra de sincronización y alerta educativa',
    backgroundTask_sync_blackboard_score_educational_alert: 'Sincronice la puntuación de la pizarra y la alerta educativa',
    backgroundTask_sync_admin_class: 'Sincronice AdminClass',

    // ROLE NAME
    roleName1: 'Propietario',
    roleName2: 'Administrador',
    roleName3: 'Profesor',
    roleName4: 'Estudiante',

    // COMMON BUTTON
    delete: 'Borrar',
    view: 'Ver',
    edit: 'Editar',
    search: "Buscar",
    next: 'Próximo',
    back: 'Atrás',
    goBack: 'Volver',
    add: 'Agregar',
    remove: 'Eliminar',
    finish: 'Terminar',
    closePreview: 'Cerrar vista previa',
    addEditTeacher: 'Agregar / Editar profesor',
    addEditStudent: 'Agregar / editar alumno',
    download: 'Descargar',

    // HEADER
    courses: 'Cursos',
    tutorials: 'Tutoriales',
    createYourSite: 'Crear tu sitio',
    profile: 'Perfil',
    configuration: 'Configuración',
    messageAndNotification: 'Mensajes y notificaciones',
    darkMode: 'Modo oscuro',
    signOut: 'Cerrar Sesión',

    // HOME PAGE
    welcomeUser: 'Bienvenido {{username}}!',
    platformNews: 'Novedades de la plataforma',
    showBy: 'Mostrar por',
    date: 'Fecha',
    youAreAlreadyPartOfInstitution: 'Ya eres parte de {{institution}}',
    createYourFirstSlide: 'Crea tu primera diapositiva',
    noNewsFromInstitution: 'No hay noticias de su institución.',
    addNews: 'Agregar noticias',
    weeks: 'semanas',

    // INSTITUTION SETTING PAGE
    visualSetting: 'Configuración visual',
    homeSetting: 'Configuración de inicio',
    scaleSetting: 'Escala de nota',

    disable: 'Desactivar',
    enable: 'Habilitar',
    header: 'Encabezado',
    headerOption_default: 'Por defecto UBOT',
    headerOption_light: 'Luz',
    headerOption_neutral: 'Neutral',
    headerOption_dark: 'Oscuro',
    active: 'Activo',
    preview: 'Vista previa',
    choose: 'Seleccionar',
    generalColor: 'Color general',
    colorOption_default: 'Por defecto UBOT',
    colorOption_happy: 'Feliz- Alegre',
    colorOption_warm: 'Cálido',
    colorOption_ocean: 'Océano',
    colorOption_forest: 'Bosque',
    colorOption_neutral: 'Neutral',
    main: 'Principal',
    complementary: 'Complementario',

    imageCarousel: 'Carrusel de imágenes',
    addNewSlide: 'Agregar nueva publicación',
    slide: 'Diapositiva ',
    button: 'Botón',
    url: 'URL',
    confirmDeleteCarouselTitle: '¿Quieres borrar el carrusel de imágenes?',
    confirmDeleteCarouselDescription: 'Esta acción es definitiva, si borras esta imagen del carrusel no tendrás acceso a ella de nuevo.',
    confirmDeleteNotificationTitle: '¿Desea eliminar la notificación?',
    confirmDeleteNotificationDescription: 'Esta acción es definitiva, si elimina esta notificación no tendrá acceso a ella de nuevo.',
    addNewNotification: 'Añadir una nueva notificación',
    scaleType: 'Tipo de escala',
    uploadedByUser: 'Subido por el usuario',
    passCriteria: 'Criterios de aprobación',
    comparativeTableScale: 'Tabla comparativa de la escala',
    scorePercentage: 'Puntuación %',
    approvalValue: 'Valor de aprobación',
    score: 'Puntuación ',
    uploadScale: 'Cargar escala',
    scaleName: 'Nombre de la escala',
    approvalScale: 'Escala de aprobación',
    uploadScaleAllowedFormats: 'Formatos permitidos: CSV y Excel',
    downloadTemplateSheet: 'Descargar hoja de plantilla',

    // CONTENT
    createCourse: 'Crear curso',
    viewTeacher: 'Ver profesor',
    viewTeachers: 'Ver profesores',
    viewStudent: 'Ver estudiante',
    viewStudents: 'Ver estudiantes',
    days: 'días',
    noTeacherEnrolled: 'Ningún profesor inscrito',
    noStudentEnrolled: 'Ningún estudiante inscrito',
    all: 'Todos',
    openCourse: 'Curso abierto',
    certifiedCourse: 'Curso certificado',
    enrolledStudent: 'Estudiantes matriculados ({{number}})',
    activity: 'Actividad',
    thisCourseIsInPreview: 'Este curso está en modo de vista previa.',
    youHaveToApproveIt: 'Tienes que aprobarlo para activarlo.',
    approve: 'Aprobar',
    archiveCourse: 'Curso de archivo',
    archiveCourseDescription1: 'Archivar el curso significa que los contenidos ya no estarán disponibles para profesores y estudiantes.',
    archiveCourseDescription2: '¿Desea continuar con el proceso de archivo?',
    noGoBack: 'No, vuelve',
    yesArchiveCourse: 'Sí, archivar el curso',
    archivedCourse: 'Cursos archivados',
    archived: 'Archivado',
    unarchive: 'Desarchivar',
    confirmDeleteCourse: '¿Desea eliminar <b>{{courseName}}?</b>',
    confirmDeleteCourseDescription: 'Eliminar este curso eliminará todo el contenido y los datos vinculados. <br/> ¿Está seguro de que desea continuar con esta acción?',
    confirmKeepCourseHistory: '¿Quieres llevar los registros históricos de las personas inscritas en el curso?',
    keepHistoricalRecord: 'Sí, llevar los registros históricos de las personas inscritas',
    noCancel: 'No, cancela',
    yesContinue: 'Si, continuar',
    continue: 'Continuar',
    courseWillBeDeletedIn24Hs: 'Tu curso será eliminado en las próximas 24hs',
    willBeDeletedIn: 'Este curso se eliminará en {{hour}}hrs',

    // COURSE CREATE
    newCourse: 'Nuevo curso',
    editCourse: 'Editar curso',
    stepTitle: 'Paso {{step}}: {{title}}',
    overview: 'Visión general',
    campus: 'Sede',
    courseImage: 'Imagen del curso',
    courseCoverLetter: 'Programa de estudios del curso',
    courseObjective: 'Objetivo del curso',
    editName: 'Editar nombre',
    addObjective: 'Agregar objetivo',
    imagePreview: 'Vista previa de la imagen',
    courseType: 'Tipo de curso',
    duration: 'Duración',
    other: 'Otro',
    defineCourseDuration: 'Definir la duración del curso (semanas)',
    defineWeekSchedule: 'Definir el horario de la semana',
    addNewDayOfClass: 'Agregar nuevo día de clase',
    createCourseStructure: 'Crear la estructura del curso',
    editCourseStructure: 'Editar la estructura del curso',
    nameOfFirstLevel: 'Nombre del primer nivel (0)',
    nameOfLastLevel: 'Nombre del último nivel',
    activityContent: 'Actividad / Contenido',
    addIntermediateLevel: 'Agregar nivel intermedio',
    nameOfIntermediateLevel: 'Nombre del nivel intermedio',
    structurePreview: 'Vista previa de la estructura',
    assignTeacher: 'Asignar profesor',

    // TEACHER LIST
    teacherTrainer: 'Profesor',
    confirmDelete: 'Quieres borrar ',
    asTeacher: ' como profesor',
    confirmDeleteUserDescription: 'Si elimina, este usuario no será un {{role}} por ahora',
    confirmDeleteUserMultipleDescription: 'Si elimina, estos usuarios no serán un {{role}} por ahora',
    confirmDeleteMultipleTeacher: '¿Quieres eliminar {{number}} profesor(es)?',
    career: 'Carrera',
    status: 'Estado',
    Active: 'Activo',
    inactive: 'Inactivo',
    accountNotVerified: 'Cuenta no verificada',
    lastConnectionTime: 'Última vez de conexión {{time}}',
    selectedTeacherNumber: 'profesor(es) seleccionado(s) {{number}}',
    downloadSpreadSheet: 'Descargar hoja de cálculo',
    lastnamesAndNames: 'Apellido/s y Nombre/s',
    contact: 'Contacto',
    addTeacherTrainer: 'Agregar profesor',
    simpleLoad: 'Carga simple',
    massiveLoad: 'Carga masiva',
    page: 'Página',
    of: 'de',
    filter: 'Filtrar',

    // TEACHER CREATE
    addNewTeacherTrainer: 'Agregar nuevo profesor',
    generalData: 'Datos general',
    personalInformation: 'Información personal',
    contactInformation: 'Información de contacto',
    addCourse: 'Añadir curso',
    youHaveNumberSelectedCourse: 'Tienes {{number}} de los cursos seleccionados',
    course: 'Curso',
    reference: 'Referencia',
    startDate: 'Fecha de inicio',
    weekSchedule: 'Horario de la semana',
    noWeekSchedule: 'Sin horario de semana',
    weekScheduleTimeRange: 'de {{start_time}} a {{finish_time}}',
    endDate: 'Fecha final',
    deadline: 'Fecha límite',
    rolePlayed: 'Papel desempeñado',
    selectedCourse: 'Cursos seleccionados',
    role: 'Rol',
    courseDuration: '{{number}} semanas',
    selectRole: 'Seleccionar rol',
    function: 'Función',

    // TEACHER CREATE MASSIVE
    massiveLoadTeacherTrainer: 'Carga masiva de profesores',
    massiveLoadTeacherDescription: 'Bienvenidos a la carga masiva de profesores. Al finalizar la carga del formulario, deberá inscribir a cada profesor en el área de estudio, carrera o curso correspondiente.',
    spreadsheetLoad: 'Carga de la hoja de cálculo',
    downloadCsvToComputer: 'A. Descargue el archivo CSV a su computadora',
    downloadTeacherInformationAsCsv: 'Descargar la información del profesor en un archivo CSV',
    downloadStudentInformationAsCsv: 'Descargar la información del estudiante en un archivo CSV',
    downloadBlankCsvTemplate: 'Descargue la plantilla CSV en blanco',
    addEditUserInformationInCsv: 'B. Agregar o editar la información del usuario en la plantilla CSV',
    spreadsheetExample: '*Ejemplo de visualización de planilla',
    uploadNewCsvFile: 'C. Cargue su nuevo archivo CSV',
    attacheACsvFile: 'Adjuntar un archivo CSV',
    templatePreview: 'Vista previa de la plantilla',
    invalidData: 'Datos inválidos',
    duplicatedEmail: 'Correo electrónico duplicado',
    duplicatedPhone: 'Teléfono duplicado',

    // TEACHER DETAIL
    editTeacherTrainer: 'Editar profesor',
    enrolledTeachers: 'Profesores matriculados',
    enrolledStudents: 'estudiantes matriculados',

    // STUDENT LIST
    addStudent: 'Agregar estudiante',
    confirmDeleteMultipleStudent: '¿Quieres eliminar {{number}} estudiante(s)?',
    asStudent: ' como estudiante',
    selectedStudentNumber: '{{number}} estudiante(s) seleccionado(s)',
    finished: 'Finalizado',
    inProcess: 'En proceso',

    // STUDENT CREATE
    addIndependentCourse: 'Agregar cursos independientes',
    wantAssignIndependentCourse: '¿Quieres asignar un curso independiente?',
    independentCourse: 'Curso independiente',
    courseSelectedNumber: 'Ha seleccionado {{number}} cursos',
    sendAccountVerificationEmail: 'Enviar correo electrónico de verificación de cuenta al estudiante',
    sendAccountVerificationEmailTeacher: 'Enviar correo electrónico de verificación de cuenta al profesor',

    // STUDENT CREATE MASSIVE
    massiveLoadStudent: 'Carga masiva de estudiantes',
    massiveLoadStudentDescription: 'Bienvenidos a la carga masiva de estudiantes. Siguiendo los pasos, podrá registrar una lista de estudiantes dentro de la misma área de estudio, carrera o curso individual.',

    // STUDENT DETAIL
    editStudent: 'Editar alumno',
    studentEnrolledToIndependentCourse: 'Este estudiante está inscrito en {{number}} cursos independientes',

    // ACCOUNT PAGE
    generalInformation: 'Información general',
    summery: 'Biografía',
    security: 'Seguridad',

    profilePhoto: 'Imagen de perfil',
    change: 'Cambiar',
    selectFile: 'Seleccionar archivo',
    allName: 'Nombre/s y Apellido/s',
    gender: 'Género',
    residenceCity: 'Ciudad de residencia',
    residenceState: 'Estado de residencia',
    residenceCountry: 'País de residencia',
    timezone: 'Zona horaria',
    identification: 'Identificación',
    passport: 'Pasaporte',
    genderundefined: 'Desconocido',
    gender0: 'Otro',
    gender1: 'Masculino',
    gender2: 'Femenino',
    addNationality: 'Agregar nacionalidad',
    cancel: 'Cancelar',
    save: 'Guardar',
    accounts: 'Cuentas',
    mainEmail: 'Correo electrónico principal',
    otherEmail: 'Otro/s correo/s electrónico/s',
    mainPhone: 'Celular principal',
    otherPhone: 'Otro/s celular/es',
    whatsapp: 'WhatsApp',
    telegram: 'Telegram',
    telegramAccount: 'Cuenta de Telegram',
    verifyAccount: 'Verificar cuenta',
    verifiedEmail: 'Todo es correcto, este correo electrónico está verificado',
    unVerifiedEmail: 'Este correo electrónico no ha sido verificado',
    unVerifiedAccount: 'Cuenta no verificada',
    verifiedAccount: 'Cuenta verificada',
    verifiedPhone: 'Todo es correcto, este número está verificado',
    unVerifiedPhone: 'Este número no ha sido verificado',
    resendVerificationEmail: 'Reenviar correo de verificación',
    sendVerificationEmailButton: 'Enviar correo de verificación',
    sendVerificationCodeButton: 'Enviar código de verificación',
    resendVerificationMessage: 'Reenviar mensaje de verificación',
    addEmail: 'Agregar correo electrónico',
    addPhone: 'Agregar celular',
    verifyTelegramAccount: 'Verificar cuenta de Telegram',
    stepNumber: 'Paso {{step}}',
    telegramVerificationStep1: 'Ingresar a Telegram',
    telegramVerificationStep2_1: 'Dirigirse al siguiente link',
    telegramVerificationStep2_2: 'o buscar el usuario',
    telegramVerificationStep3: 'Enviar a través de este medio el correo electrónico con el cuál estás registrado en este sistema',
    enterVerificationCode: 'Ingresar código de verificación',
    makeSureDigitCode: 'Asegúrate de que reciviste el código de 6 dígitos',
    resendCode: 'Reenviar código',
    coverLetter: 'Biografía',
    tagTitle: 'Estoy interesado en',
    userLanguageTitle: 'Idiomas',
    mainUserLanguage: 'Idioma principal',
    otherUserLanguage: 'Otros idiomas',
    changePassword: 'Cambiar contraseña',

    // UBOT HOME
    ubotHome: 'Página de inicio de Ubot',
    noInstitution: 'Aún no tienes ninguna institución',
    noInstitutionConfiguration: 'No tienes ninguna institución para configurar',
    completeProfileMessage: 'Recuerda completar tu perfil aquí :)',
    homeCardTitle1: 'Quieres estudiar algo?',
    homeCardTitle2: 'Quieres enseñar?',
    homeCardTitle3: 'Crear una nueva institución',
    homeCardSubTitle1: 'Cursos abiertos',
    homeCardButton1: 'Buscar cursos',
    homeCardButton2: 'Crear un curso',
    homeCardButton3: 'Crear institución',

    // UBOT INSTITUTION CREATE
    backToHome: 'Volver al inicio',
    choosePlanTitle: '¡Encontremos el plan perfecto para vos!',
    choosePlanDescription: 'Por favor, selecciona algunas opciones a continuación para que Smarty pueda ayudarte a encontrar un plan ideal para tus necesidades.',
    searchMyPlan: 'Buscar mi plan',
    searchAgain: 'Buscar nuevamente',
    customizePlanTitle: 'Customizar mi plan',
    confirmPlanTitle: 'Confirmar plan',
    confirmPlanDescription: 'Por favor, brindenos algo de información sobre su institución',
    institutionInformation: 'Información de la institución',
    billingAddress: 'Dirección de facturación',
    confirmPlan: 'Confirmar plan',
    institutionSuccessEmailTitle: '¡Gracias por crear una institución con nosotros!',
    institutionSuccessEmailDescription: 'Recibirá un correo de verificación con los detalles de pago0',
    perMonth: 'P / mes',
    requestDemo: 'Solicite una demostración',
    freePlan: 'Plan gratuito',
    requestDemoDescription: 'Seleccione esta opción para crear una demostración específica para usted',
    customizePlan: 'Personalizar plan',
    customizePlanDescription: 'Seleccione esta opción para crear una demostración específica para usted',
    RECOMMENDED_PLAN: 'PLAN RECOMENDADO',
    DEMO_PLAN: 'PLAN DE DEMOSTRACIÓN',
    FREE_PLAN: 'PLAN GRATIS',
    CUSTOMIZE_PLAN: 'PERSONALIZAR PLAN',
    selectPlan: 'Seleccionar plan',
    editPlan: 'Editar plan',

    currency: 'Divisa',
    currency_usd: 'USD Dólar estadounidense',
    currency_clp: 'CLP Peso Chileno',
    currency_ars: 'ARS Peso Argentino',
    currency_brl: 'BRL Real brasileño',

    productDescription_institution_course_number: 'Cantidad máxima de {{number}} cursos',
    productDescription_institution_student_number: 'Máximo de {{number}} estudiantes',
    productDescription_use_telegram: 'Usar Telegram',
    productDescription_use_whatsapp: 'Usar WhatsApp',
    productDescription_course_student_number: 'Máximo de {{number}} estudiantes por curso',
    productDescription_storage: '{{number}} GB de datos guardados',
    productDescription_other_platform: 'Integración con otras plataformas',
    productDescription_access_analytics: 'Acceso a analíticas',
    productDescription_access_communication: 'Acceso a la comunicación',
    productDescription_survey_number: 'Máximo de {{number}} encuestas',
    productDescription_workspace_number: 'Máximo de {{number}} espacios de trabajo',
    productDescription_message_number: 'Máximo de {{number}} mensajes',

    sectorName_education: 'Educación',
    sectorName_business: 'Empresa / Negocio',
    sectorName_ngo: 'ONG / Fundación',
    sectorName_go: 'Institución gubernamental',
    sectorName_person: 'Profesor / profesor independiente',
    noSubSector: 'Sin subsector',

    subSectorName_public_university: 'Universidad pública',
    subSectorName_private_university: 'Universidad privada',
    subSectorName_public_tertiary_education: 'Educación terciaria pública',
    subSectorName_private_tertiary_education: 'Educación terciaria privada',
    subSectorName_public_college: 'Universidad pública / escuela secundaria',
    subSectorName_private_college: 'Universidad privada / escuela secundaria',
    subSectorName_institution: 'Instituto',

    subSectorName_farming: 'Agricultura; plantaciones, otros sectores rurales',
    subSectorName_feeding: 'Alimentación; bebidas tabaco',
    subSectorName_commerce: 'Comercio',
    subSectorName_construction: 'Construcción',
    subSectorName_education: 'Educación',
    subSectorName_transport_manufacture: 'Fabricación de material de transporte',
    subSectorName_public_function: 'Función pública',
    subSectorName_hotel_restoraunt: 'Hoteles, restaurantes, turismo',
    subSectorName_chemical_industry: 'Industrias quimicas',
    subSectorName_medical_electrical_engineering: 'Ingeniería mecánica y eléctrica',
    subSectorName_media_culture: 'Medios, cultura; gráficos',
    subSectorName_mining: 'Minería (carbón, otra minería)',
    subSectorName_oil_gas_production: 'Producción de petróleo y gas; refinación de petróleo',
    subSectorName_base_metal_production: 'Producción de metales base',
    subSectorName_postal_telecommunication_service: 'Servicios postales y de telecomunicaciones',
    subSectorName_health_service: 'Servicios de salud',
    subSectorName_financial_service: 'Servicios financieros; servicios profesionales',
    subSectorName_utilities: 'Servicios públicos (agua, gas, electricidad)',
    subSectorName_forestry: 'Silvicultura; madera, celulosa; papel',
    subSectorName_transportation: 'Transporte (incluida la aviación civil; ferrocarriles; transporte por carretera)',
    subSectorName_marine_transport: 'Transporte marítimo; puertos, pesca; transporte interno',

    // UBOT INSTITUTION DASHBOARD
    admin: 'Administrador',
    studentApprentice: 'Estudinate / Aprendiz',
    viewInstitution: 'Ver institución',
    goToAcademics: 'Ir a Academics',
    statistics: 'Estadísticas',
    goToAnalytics: 'Ir a Analytics',
    messages: 'Mensajes',
    goToCommunication: 'Ir a Communication',
    activeCourse: 'Cursos activos',
    activeTeacher: 'Profesores activos',
    activeStudent: 'Estudiantes activos',
    courseInProgress: 'Cursos en progreso',
    approved: 'Aprobados',
    notApproved: 'No aprobado',
    toQualify: 'Para calificar',
    qualified: 'Calificado',
    completed: 'Terminado',
    activityDelivered: 'Actividades entregadas',
    ratedActivities: 'Actividades calificadas',
    disapproved: 'Desaprobados',
    courseEscape: 'Tasa de abandono',
    qualification: 'Calificación',
    gradeByScale: 'Grado por escala',
    youHaveUncheckedMessage: 'Tu tienes {{ number }} mensajes sin leer',
    youHaveUnsentSurvey: 'Tu tienes {{ number }} encuestas sin enviar',
    youHaveNoCourse: 'No tienes cursos aquí.',

    // UBOT INSTITUTION CONFIGURATION
    institutionInfo: 'Información institucional',
    ownerAdmin: 'Propietario / Administradores',
    plan: 'Plan',
    institutionType: 'Tipo de institución',
    schedule: 'Cronograma',
    institutionImage: 'Institution image',
    subOffice: 'Oficinas',
    addOffice: 'Agregar una nueva oficina',
    owner: 'Propietario',
    changeOwner: 'Cambiar propietario',
    youAreOwner: 'Tu eres el propietario de esta institución',
    admins: 'Administradores',
    firstnames: 'Nombre/s',
    lastnames: 'Apellido/s',
    product: 'Productos',
    addAdmin: 'Agregar administrador',
    newAdmin: 'Nuevo administrador',
    yourPlan: 'Tu plan',
    changeYourPlan: 'Cambiar tu plan',
    contactPhone: 'Número telefónico de contacto',
    noSubOffice: 'Sin oficina secundaria',
    noData: 'Sin datos',
    confirmGiveOwnerAccess: '¿Estás seguro darle el acceso de propietario a ',
    confirmDeleteAdmin: '¿Está seguro de eliminar a {{name}} como administrador?',
    giveOwnerAccessDescription1: 'Si das acceso de propietario, serás un administrador.',
    giveOwnerAccessDescription2: 'Solo puedes recuperar el propietario si alguien te asigna.',
    confirmDeleteAdminDescription: 'Si lo eliminas, este usuario ya no será un administrador.',
    selectProduct: 'Seleccionar producto',
    categoryName_academic: 'Academics',
    categoryName_analytics: 'Analytics',
    categoryName_communication: 'Communications',
    selectAll: 'Seleccionar todo',
    permissionName_create_career: 'Crear carrera',
    permissionName_create_approve_course: 'Crear y aprobar curso',
    permissionName_calendar_broadcast: 'Calendario y lista de difusión',
    permissionName_access_analytics: 'Acceso a Analytics',
    permissionName_access_communication: 'Acceso a Communications',
    permissionName_chat_teacher: 'Chatear con el profesor',
    permissionName_chat_student: 'Chatear con el alumno',
    permissionName_send_survey_template: 'Enviar encuesta y plantillas',

    // CHAT
    students: 'Estudiantes',
    administrator: 'Administrador',
    broadcastList: 'Lista de difusión',
    message: 'Mensaje',
    smarty: 'Smarty',
    groups: 'Grupos',
    hello: 'Hola!',
    youCanSelectConversationFromInbox: 'Puede seleccionar una conversación en la bandeja de entrada.',
    links: 'Enlaces',
    contents: 'Contenidos',
    smartyWhatsapp: 'Smarty WhatsApp',
    smartyTelegram: 'Smarty Telegram',
    smartyAnswering: 'Smarty está respondiendo actualmente a este chat.',
    stopChatbot: 'DETENER CHATBOT',
    surveyAnswering: 'Este estudiante está respondiendo una encuesta en este momento. Vuelva a intentarlo más tarde.',
    adminAnswering: 'Actualmente, un administrador está respondiendo a este alumno. Vuelve a intentarlo más tarde.',
    teacherAnswering: 'Otro profesor está respondiendo actualmente a este alumno. Vuelve a intentarlo más tarde.',
    activateChatbot: 'ACTIVAR CHATBOT',
    smartyWillBeActivated: 'Smarty se activará automáticamente en {{min}} minutos',
    editTemplate: 'Editar plantilla',
    whatsappLimitTitle: 'Has alcanzado tu límite de 24 horas en WhatsApp',
    whatsappLimitDescription: 'Hay una ventana de 24 horas para charlar con un cliente sin pagar tarifas de servicio adicionales. Puede mantenerse en contacto enviando un mensaje de plantilla de WhatsApp aprobado.',
    customFiled: 'Campo personalizado',
    send: 'Enviar',
    groupFiles: 'Grupo archivos',
    addFileLink: 'Añadir archivo / enlace',
    mediaFile: 'Archivo multimedia',
    addLink: 'Añadir enlace',
    upload: 'Subir',
    addMediaFile: 'Agregar archivo multimedia',
    attachFile: 'Adjuntar un archivo',
    textAndMultimediaFile: 'Archivos de texto y multimedia',
    newBroadcastList: 'Nueva lista de difusión',
    reasonForMessage: 'Motivo del mensaje',
    describeMessageToNotify: 'Describa el mensaje para notificar',
    publication: 'Publicación',
    immediate: 'Inmediato',
    scheduled: 'Programado',
    recipients: 'Destinatarios',
    addNewCourse: 'Agregar nuevo curso',
    courseReference: 'Curso / Referencia',
    indicateWhoReceiveMessage: 'Indica a quién quieres enviar el mensaje',
    addGroups: 'Agregar grupos',
    previewMessage: 'Vista previa del mensaje',
    showMessagePreview: 'Mostrar vista previa del mensaje',
    hideMessagePreview: 'Ocultar vista previa del mensaje',
    previewMode: 'Modo de vista previa',
    broadcastPreviewParameterDescription: '<b>Importante: </b> El <b>nombre del estudiante</b> y el <b>nombre del profesor </b> son variables que el sistema completa automáticamente.',
    noMessageTemplate: 'Sin plantilla de mensaje',
    studentName: 'Nombre del estudiante',
    teacherName: 'Nombre del profesor',
    myBroadcast: 'Mi difusión',
    deletedUser: 'Usuario eliminado',

    // MY COURSE
    courseStatus0: 'Cursos activos',
    "courseStatus-1": 'Cursos de borrador',
    courseStatus1: 'Cursos terminados',
    activities: 'Actividades',
    editStructure: 'Editar estructura',
    duplicate: 'Duplicado',
    archive: 'Archivo',
    editStructureMode: 'Modo de edición de estructura',
    addNewContent: 'Agregar nuevo contenido',
    newItem: 'Nuevo artículo',
    newActivity: 'Nueva actividad',
    editActivity: 'Editar actividad',
    createActivityStepSelectActivityType: 'Paso 1: Seleccione el tipo de actividad',
    createActivityStepAddNew1: 'Paso 2: Agregar nueva clase',
    createActivityStepAddNew2: 'Paso 2: Agregar nueva tarea',
    createActivityStepAddNew3: 'Paso 2: Agregar nueva evaluación',
    createActivityStepAddNew4: 'Paso 2: Agregar nuevo taller',
    createActivityStepAddNew7: 'Paso 2: Agregar nueva teoría',
    createActivityStepCharacter1: 'Paso 3: Definir las características de la clase',
    createActivityStepCharacter2: 'Paso 3: Definir las características de la tarea',
    createActivityStepCharacter3: 'Paso 3: Definir las características de la evaluación',
    createActivityStepCharacter4: 'Paso 3: Definir las características de la taller',
    createActivityStepUploadContent1: 'Paso 4: Cargue el contenido de la clase',
    createActivityStepUploadContent2: 'Paso 4: Cargue el contenido de la tarea',
    createActivityStepUploadContent3: 'Paso 4: Cargue contenido de evaluación',
    createActivityStepUploadContent4: 'Paso 4: Cargue contenido de taller',
    createActivityStepUploadContent7: 'Paso 3: Subir contenido teórico',
    createActivityStepSelectActivityTypeDescription: 'Selecciona el tipo de actividad que quieres subir',
    activityTypeName1: 'Clase',
    activityTypeName2: 'Tarea',
    activityTypeName3: 'Evaluación',
    activityTypeName4: 'Taller',
    activityTypeName5: 'Encuesta',
    activityTypeName6: 'Simulador',
    activityTypeName7: 'Teoría',
    mandatory: 'Obligatorio',
    notMandatory: 'No obligatorio',
    selectOneOrBoth: 'Seleccione una o ambas opciones',
    inPerson: 'En persona',
    virtual: 'Virtual',
    faceToFaceRoom: 'sala cara a cara',
    onlineRoom: 'Sala en vivo',
    addNewLink: 'Agregar nuevo enlace',
    textMultimediaFiles: 'Archivos de texto y multimedia',
    uploadFiles: 'Subir archivos',
    individual: 'Individual',
    group: 'Grupo',
    withQualification: 'Con calificación',
    withoutQualification: 'Sin calificación',
    uploadFileTypeDocument: 'Documentos de texto (doc, docx, pdf)',
    uploadFileTypeMedia: 'Imágenes/vídeo (jpg, avi, mp4)',
    uploadFileTypePresentation: 'Presentación (ppt, pptx)',
    noFile: 'Sin archivo',
    noLink: 'Sin enlace',
    youAreAlmostDone: '¡Ya casi terminas!',
    createActivityPopUpDescription: 'Vamos a publicar un adelanto de la actividad. Comprueba que todo está bien y publícalo.',
    noActivity: 'Sin actividades',
    activityCharacter: 'Características de la actividad',
    localOnlineTypeText1: 'Presencial',
    localOnlineTypeText2: 'Virtual',
    localOnlineTypeText3: 'Virtual o presencial',
    activityRoom: 'Sala de actividades',
    easyLMSInfo: 'Información de Easy LMS',
    easyLMSExam: 'Examen de Easy LMS',
    liveActivity: 'Actividad en vivo',
    location: 'Localización',
    link: 'Enlace',
    files: 'Archivos',
    publish: 'Publicar',
    'activityStatusText-1': 'Para comenzar',
    'activityStatusText0': 'En proceso',
    'activityStatusText1': 'Terminado',
    backToDraft: 'Volver al borrador',
    delivered: 'Entregado',
    rating: 'Calificación',
    note: 'Nota',
    snote: 'nota',
    attendance: 'Asistencia',
    startActivity: 'Iniciar actividad',
    notDelivered: 'No entregado',
    onTime: 'A tiempo',
    outOfTime: 'Fuera de tiempo',
    present: 'Presente',
    absence: 'Ausencia',
    absent: 'Ausente',
    noGroup: 'Sin grupo',
    uploadYourContent: 'Sube tu contenido',
    textAndMultimediaFiles: 'Archivos de texto y multimedia',
    cannotRemoveCourseContent: 'No puede eliminar o mover esta unidad porque está en progreso',
    syncBlackboard: 'Sync blackboard',
    syncAdminClass: 'Sync AdminClass',

    // COURSE INFO
    courseCharacter: 'Características del curso',
    teachers: 'Profesores',
    courseStudyProgram: 'Programa de estudio del curso',

    // GROUP
    addNewGroup: 'Agregar nuevo grupo',
    createGroup: 'Crea un grupo',
    groupName: 'Nombre del grupo',
    groupActivities: 'Actividades de grupo',
    selectGroupActivities: 'Seleccionar actividades de grupo',
    notSelectActivityYet: 'No seleccionar actividad todavía',
    groupImage: 'Imagen de grupo',
    groupImageOption1: 'Cargue el archivo desde su computadora ({{size}} MB máx.)',
    groupImageDescription1: 'El formato de la imagen debe ser JPG o PNG y tener un tamaño superior a 250 px x 250 px.',
    groupImageOption2: 'Subir archivo desde enlace',
    groupImageOption3: 'Usar imagen predeterminada',
    members: 'Miembros',
    addNewMembers: 'Agregar nuevos miembros',
    changeResponsible: 'Cambiar responsable',
    minimumNumberOfStudents: 'Número mínimo de estudiantes',
    maximumNumberOfStudents: 'Número máximo de estudiantes',
    selectResponsible: 'Seleccionar responsable',
    unselect: 'Deseleccionar',
    responsible: 'Responsable',
    noMember: 'Ningún miembro',
    groupLockIconDescription: 'Grupo de bloqueo / desbloqueo. Los estudiantes no podrán editarlo mientras esté bloqueado.',
    recentActivity: 'Actividad reciente',
    otherGroups: 'Otros grupos',
    join: 'Entrar',
    viewGroup: 'Ver grupo',
    membershipRequest: 'Solicitud de membresía',
    joinRequest: '{{name}} quiere unirse al grupo',
    acceptRequest: 'Aceptar petición',
    enteredAt: 'Ingresó al grupo el {{datetime}}',
    leftAt: 'Dejó el grupo el {{datetime}}',
    sendMessage: 'Enviar mensaje',
    editGroup: 'Editar grupo',
    deleteGroup: 'Eliminar grupo',
    confirmDeleteGroupTitle: '¿Deseas eliminar {{name}}?',
    confirmDeleteGroupDescription: 'Esta acción es definitiva, si eliminas el grupo no volverás a tener acceso a él o su contenido.',
    you: "Usted",
    groupActionDescription1: '{{userName}} creó {{groupName}}.',
    groupActionDescription2: '{{userName}} pidió unirse a {{groupName}}.',
    groupActionDescription3: '{{userName}} se unió a {{groupName}}.',
    groupActionDescription4: '{{userName}} abandonó el {{groupName}}.',
    awaitingAcceptance: 'En espera de aceptación',
    studentNotAllowedInGroupActivity: 'Este alumno ya tiene un grupo con las mismas actividades',
    youAreInOtherGroupWithThisActivity: 'Estás en otro grupo para estas actividades',
    alreadyDeliveredActivity: 'Esta actividad ya fue entregada',
    memberNumberOverflow: 'Este grupo ya tiene un número máximo de miembros',

    // EVALUATION
    qualifications: 'Calificaciones',
    history: 'Historia',
    rateAll: 'Calificar todo',
    rateActivity: 'Calificar actividad',
    viewActivity: 'Ver actividad',
    allActivities: 'Todas las actividades',
    superiorLevels: 'Niveles superiores',
    assessment: 'Ponderación',
    assessmentOfExtraActivities: 'Ponderación actividades extra',
    groupActivity: 'Actividad de grupo',
    average: 'Promedio',
    equivalentGradeByScale: 'Grado equivalente por escala',
    studentStatus: 'Estado del estudiante',
    individualQualification: 'Cualificación individual',
    groupQualification: 'Calificación de grupo',
    comments: 'Comentarios',
    deliveredContent: 'Contenidos entregados',
    activityStatus: 'Estado de actividad',
    equivalentMarks: 'Marcas equivalentes',
    ratingPercentage: 'Porcentaje de calificación',
    equivalentScaleNote: 'Equivalente en la escala a la nota {{number}}',
    recoveryOfActivity: 'Recuperación de la actividad',
    recoveryActivityDescription: 'Indica si esta actividad se puede recuperar',
    activityCanBeRecovered: 'Si, se puede recuperar',
    activityCannotBeRecovered: 'No, no se puede recuperar.',
    qualify: 'Calificar',
    youRated: 'Calificaste a {{name}}',
    thisActivityCanBeRecovered: 'Esta actividad se puede recuperar',
    thisActivityCannotBeRecovered: 'Esta actividad no se puede recuperar',
    editQualification: 'Editar calificación',
    qualifyIndividually: 'Calificar individualmente',
    rateGroup: 'Grupo de tarifas',
    grade: 'Calificación',
    delivery: 'Entrega',
    deliver: 'Entregar',
    viewDelivery: 'Ver entrega',
    courseProgress: 'Progreso del curso: {{percent}}% completo',

    // ALERT
    alerts: 'Alertas',
    settings: 'Configuración',
    enableAlert: 'Activar',
    educationalAlerts: 'Alertas educativas',
    alertType_dashboard: 'Panel',
    alertType_platform_interact_teacher: 'Inactividad con plataformas',
    alertType_platform_interact_student: 'Inactividad con plataformas',
    alertType_qualification_time: 'Tiempo de calificación',
    alertType_performance: 'Rendimiento',
    alertType_pass_rate: 'Tasa de aprobación',
    alertType_activity_delivery_status: 'Estado de entrega de actividad',
    alertType_duoc_online: 'Duoc online',
    teachersSettings: 'Configuración de profesores',
    studentsSettings: 'Configuración de los estudiantes',
    earlyWarning: 'Advertencia temprana',
    finalWarning: 'Última advertencia',
    settingFormLabel_platform_interact_teacher_early: 'Porcentaje de inactividad',
    settingFormLabel_platform_interact_teacher_final: 'Porcentaje de inactividad',
    settingFormLabel_platform_interact_student_early: 'Porcentaje de inactividad',
    settingFormLabel_platform_interact_student_final: 'Porcentaje de inactividad',
    settingFormLabel_qualification_time_early: 'Tiempo de calificación en días',
    settingFormLabel_qualification_time_final: 'Tiempo de calificación en días',
    settingFormLabel_performance_early: 'Promedio de porcentaje de calificación',
    settingFormLabel_performance_final: 'Promedio de porcentaje de calificación',
    settingFormLabel_pass_rate_early: 'Porcentaje de actividades sin pase',
    settingFormLabel_pass_rate_final: 'Porcentaje de actividades sin pase',
    settingFormLabel_activity_delivery_status_early: 'Porcentaje de actividades entregadas fuera de plazo',
    settingFormLabel_activity_delivery_status_final: 'Porcentaje de actividades no entregadas',
    alertDescription_platform_interact_student: 'La alerta muestra el porcentaje de estudiantes que no han interactuado con las plataformas.',
    alertDescription_platform_interact_teacher: 'La alerta muestra el porcentaje de profesores que no han interactuado con las plataformas.',
    alertDescription_qualification_time: 'La alerta muestra el tiempo promedio que los profesores dedican a calificar una actividad después del tiempo de entrega por curso.',
    alertDescription_performance: 'La alerta muestra el promedio de calificación en porcentaje y nota que tienen los alumnos en un curso, considerando todos los tipos de actividad:',
    alertDescription_pass_rate: 'La alerta muestra el promedio de índice de no aprobados que tienen los alumnos en un curso, considerando todos los tipos de actividad:',
    alertDescription_delivery_status: 'La alerta muestra el porcentaje de entregas de alumnos con estatus de:',
    nonInteractionWarning: 'Advertencias de no interacción',
    warningAlerts: 'Alertas de advertencia',
    globalAverageOfNonInteraction: 'Promedio global de no interacción',
    globalAverageOfQualificationTime: 'Promedio global de tiempo de calificación',
    globalAverageOfQualificationPercentage: 'Promedio global de porcentaje de calificación',
    totalNumberCourseAnalyzed: 'Número total de cursos analizados',
    totalNumberOfUsersOnAlert: 'Número total de usuarios en alerta',
    totalNumberOfMessagesNotificationsSent: 'Número total de notificaciones de mensajes enviadas',
    sendAlertMessageTo: 'Enviar mensaje de alerta a',
    allStudents: 'Todos los estudiantes',
    allTeachers: 'Todos los profesores',
    studentsWithEarlyWarning: 'Estudiantes con alerta temprana',
    teachersWithEarlyWarning: 'Profesores con alerta temprana',
    studentsWithFinalWarning: 'Estudiantes con advertencia final',
    teachersWithFinalWarning: 'Profesores con advertencia final',
    platform: 'Plataforma',
    alertMessage: 'Mensaje de alerta',
    platformName_1: 'Plataforma ubot',
    platformName_2: 'Blackboard',
    noInteractAlertDataDescription: 'No es posible tomar los datos requeridos de esta plataforma.',
    notInteractedDescription_platform_interact_student: 'de los estudiantes no han interactuado',
    notInteractedDescription_platform_interact_teacher: 'de profesores no han interactuado',
    performanceAverageDescription: 'porcentaje de calificación promedio',
    messagePreview: 'Vista previa del mensaje',
    selectStudentWarning: 'Seleccione los estudiantes con advertencia temprana o final para ver la vista previa del mensaje.',
    noTemplate: 'Sin plantilla de mensaje',
    sentAt: 'Enviado a',
    qualificationTimeWarning: 'Tiempo de corrección del curso más largo de lo esperado',
    qualificationTimeCorrect: 'Tiempo correcto de calificación del curso',
    nonPassPercentage: '{{percentage}}% de no aprobados',
    globalAverageOfNonPassActivity: 'promedio global de actividades no aprobadas',
    nonPassAverageDescription: 'Actividades no aprobadas',
    nonDeliveryAverageDescription: 'Actividades no entregadas',
    notDeliveryPercentage: '{{percentage}}% no entregado',
    outOfTimeDeliveryPercentage: '{{percentage}}% entrega fuera de tiempo',
    globalAverageOfNotDeliverActivities: 'Promedio global de actividades no entregadas',
    allCourses: 'Todos los cursos',
    activitiesPassRate: 'Tasa de aprobación de actividades',
    typeOfActivity: 'Tipo de actividad',
    numberOfActivities: 'Número de actividades',
    ratingDistribution: 'Distribución de calificaciones',
    numberOfStudents: 'Numero de estudiantes',
    averageInPercentage: 'Promedio en porcentaje',
    deactivateWhatsappAlert: 'Desactivar el envío automático de alertas por WhatsApp',
    syncAll: 'Sincronizar todo',
    syncAllButtonTooltip: 'La sincronización automática se realiza cada 30 minutos. Haga clic en este botón si desea sincronizar ahora.',
    downloadAsExcel: 'Descargar como Excel',
    interactionWithPlatform: 'Interacción con el curso',
    ponderationActivities: 'Promedio de actividades sumativas',
    percentageOfDeliveries: 'Porcentaje de entregas',
    DUOCOnlineAlertStatus1: 'Verde1',
    DUOCOnlineAlertStatus2: 'Amarillo1',
    DUOCOnlineAlertStatus3: 'Amarillo2',
    DUOCOnlineAlertStatus4: 'Amarillo3',
    DUOCOnlineAlertStatus5: 'Rojo1',
    DUOCOnlineAlertStatus6: 'Rojo2',
    DUOCOnlineAlertStatus7: 'Rojo3',
    DUOCOnlineAlertStatus8: 'Rojo4',
    courseTotalDays: 'Días totales del curso',
    updateInformation: 'Actualizar Información',
    process_started_titleS: 'Aviso',
    process_started: 'Tú solicitación ha sido enviada. El proceso há iniciado y tardará algunos minutos.',

    // STATISTICS
    alertAndStatistics: 'Alertas y estadísticas',
    activityRating: 'Calificación de actividad',
    totalNumberOfActivities: 'Número total de actividades',
    qualifiedActivities: 'Actividades calificadas',
    activitiesToBeGraded: 'Actividades a calificar',
    recover: 'Recuperar',
    undelivered: 'No entregado',
    tests: 'Pruebas',
    otherActivities: 'Otras actividades',
    courseAlert_studentsNotInteracted: '3% de los estudiantes no ha interactuado.',
    courseAlert_qualificationAverage: 'promedio de calificación',
    courseAlert_nonDeliverActivities: 'actividades no entregadas',
    deliversOutOfTime: 'entrega fuera de tiempo',
    notDelivers: 'no entregado',
    ofNonPass: 'de no aprobados',
    courseAlert_courseCorrectionTime: 'Tiempo de corrección del curso más largo de lo esperado',
}