import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    campusData: null,
    careerData: null,
    scaleData: null,
    courseCategoryData: null,
    workspaceData: null,
    adminData: null,
    templateData: null,
    alertTemplateData: null,
    groupData: null,
};

const updateCampusData = (state, action) => {
    return updateState(
        state,
        {
            campusData: action.campusData
        }
    )
};

const updateCareerData = (state, action) => {
    return updateState(
        state,
        {
            careerData: action.careerData
        }
    )
};

const updateScaleData = (state, action) => {
    return updateState(
        state,
        {
            scaleData: action.scaleData
        }
    )
};

const updateCourseCategoryData = (state, action) => {
    return updateState(
        state,
        {
            courseCategoryData: action.courseCategoryData
        }
    )
};

const updateWorkspaceData = (state, action) => {
    return updateState(
        state,
        {
            workspaceData: action.workspaceData
        }
    )
};

const updateAdminData = (state, action) => {
    return updateState(
        state,
        {
            adminData: action.adminData
        }
    )
};

const updateTemplateData = (state, action) => {
    return updateState(
        state,
        {
            templateData: action.templateData
        }
    )
};

const updateAlertTemplateData = (state, action) => {
    return updateState(
        state,
        {
            alertTemplateData: action.alertTemplateData
        }
    )
};

const updateGroupData = (state, action) => {
    return updateState(
        state,
        {
            groupData: action.groupData
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_CAMPUS_DATA: return updateCampusData(state, action);
        case actionTypes.UPDATE_CAREER_DATA: return updateCareerData(state, action);
        case actionTypes.UPDATE_SCALE_DATA: return updateScaleData(state, action);
        case actionTypes.UPDATE_COURSE_CATEGORY_DATA: return updateCourseCategoryData(state, action);
        case actionTypes.UPDATE_WORKSPACE_DATA: return updateWorkspaceData(state, action);
        case actionTypes.UPDATE_ADMIN_DATA: return updateAdminData(state, action);
        case actionTypes.UPDATE_TEMPLATE_DATA: return updateTemplateData(state, action);
        case actionTypes.UPDATE_ALERT_TEMPLATE_DATA: return updateAlertTemplateData(state, action);
        case actionTypes.UPDATE_GROUP_DATA: return updateGroupData(state, action);
        default: return state;
    }
};

export default reducer;